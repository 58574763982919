import { getPhoneNumberRegion } from '@/utils/phoneUtils'

export type RuleResponse = string | true
export type Rule<T> = (v: T) => RuleResponse

export const orderIdRegex = /^[^\d]*(\d{6,7})$/i

export default class RulesUtils {
  required (v: string): RuleResponse {
    if (!v) {
      return 'Ce champ est obligatoire'
    }
    return true
  }

  phoneNumber (v: string): RuleResponse {
    if (!v) return true
    if (!getPhoneNumberRegion(v)) {
      return 'Ce champ doit être un numéro de téléphone valide'
    }
    return true
  }

  orderId (v: string): RuleResponse {
    if (!v) return true
    if (!v.match(orderIdRegex)) {
      return 'Ce champ doit être un numéro de commande valide'
    }
    return true
  }
}
