








































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreDTO, StoreReferenceDTO, StoreReferenceListExcerptDTO, UserExcerpt } from '@/api/dto'
import { Watch } from 'vue-property-decorator'
import client from '@/api/client'
import StoreReferences from './StoreReferences.vue'
import StoreReferenceList from './StoreReferenceList.vue'
import StoreAdmins from './StoreAdmins.vue'
import { MenuEntry } from '@/state/menu'
import vuexStore from '@/state/store'
import { namespace } from 'vuex-class'

const Data = namespace('data')
const Loading = namespace('loading')
const Menu = namespace('menu')

@Component({
  components: { StoreReferences, StoreReferenceList, StoreAdmins }
})
export default class StoreDetails extends Vue {
  @Loading.Getter
  loading: boolean

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Data.Getter
  users: UserExcerpt[]

  @Data.Action
  loadStores: () => Promise<StoreDTO>

  store: StoreDTO = null
  storeReferences: StoreReferenceDTO[] = []
  storeReferenceLists: StoreReferenceListExcerptDTO[] = []
  selectedReferenceList: StoreReferenceListExcerptDTO = null

  selectedUser: UserExcerpt = null

  async created (): Promise<void> {
    this.setMenuEntries([{
      label: 'Changer de magasin',
      icon: 'mdi-package-variant',
      action: () => this.$router.push({ name: 'Stores' })
    }])

    vuexStore.commit('loading/startLoading', StoreDetails.name)

    try {
      const response = await Promise.all([
        client.get(`stores/${this.$route.params.id}`),
        client.get(`stores/${this.$route.params.id}/references?withComputedQuantity=true`),
        client.get(`stores/${this.$route.params.id}/possible-parent-store-reference-lists`),
        client.get(`stores/${this.$route.params.id}/store-reference-list/parent`)
      ])

      const store: StoreDTO = response[0].data
      const storeReferences: StoreReferenceDTO[] = response[1].data
      const storeReferenceLists: StoreReferenceListExcerptDTO[] = response[2].data
      const selectedReferenceList: StoreReferenceListExcerptDTO = response[3].data

      this.store = store
      this.storeReferences = storeReferences
      this.storeReferenceLists = storeReferenceLists
      this.selectedReferenceList = selectedReferenceList
    } catch (err) {
      Vue.config.errorHandler(err, this, null)
      throw err
    } finally {
      vuexStore.commit('loading/stopLoading', StoreDetails.name)
    }
  }

  async reloadReferences (): Promise<void> {
    const response = await client.get(`stores/${this.$route.params.id}/references?withComputedQuantity=true`)
    this.storeReferences = response.data
  }

  async onStoreAdminsChanged (admins: UserExcerpt[]): Promise<void> {
    this.store.admins = admins
    await this.loadStores()
  }

  @Watch('selectedReferenceList')
  async onSelectedReferenceListChanged (): Promise<void> {
    await this.reloadReferences()
  }
}
