import { Module } from 'vuex'

import { RootState } from './store'
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js'

export interface AuthState {
  token: string | null
  tokenParsed: KeycloakTokenParsed | null
  profile: KeycloakProfile | null,
  userInfo: Record<string, unknown> | null
}

const auth = {
  namespaced: true,
  state: {
    token: null,
    tokenParsed: null,
    profile: null,
    userInfo: null
  } as AuthState,
  mutations: {
    setToken (state: AuthState, token: string) {
      state.token = token
    },
    setTokenParsed (state: AuthState, tokenParsed: KeycloakTokenParsed) {
      state.tokenParsed = tokenParsed
    },
    setProfile (state: AuthState, profile: KeycloakProfile) {
      state.profile = profile
    },
    setUserInfo (state: AuthState, userInfo: Record<string, unknown>) {
      state.userInfo = userInfo
    }
  },
  getters: {
    token (state: AuthState): string | null {
      return state.token
    },
    tokenParsed (state: AuthState): KeycloakTokenParsed | KeycloakTokenParsed {
      return state.tokenParsed
    },
    profile (state: AuthState): KeycloakProfile | KeycloakProfile {
      return state.profile
    },
    userInfo (state: AuthState): Record<string, unknown> {
      return state.userInfo
    },
    userId (state: AuthState): string | null {
      if (state.tokenParsed && state.tokenParsed.sub) {
        return state.tokenParsed.sub
      }
      return null
    }
  }
} as Module<AuthState, RootState>

export default auth
