

















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import ReferenceChildren from './ReferenceChildren.vue'
import { ChildReferenceDTO, ReferenceDTO } from '@/api/dto'

@Component({
  components: { ReferenceChildren }
})
export default class Reference extends Vue {
  @Prop({ type: Object, required: true })
  value: ReferenceDTO

  @Prop({ type: Array, required: true })
  references: ReferenceDTO[]

  @Prop({ type: Boolean, default: false })
  edit: boolean

  pack = false

  @Watch('value', { immediate: true })
  onValueChanged (value: ReferenceDTO): void {
    this.pack = value.children && value.children.length > 0
  }

  @Watch('pack')
  onContentChanged (content: boolean): void {
    if (content && !this.value.children) {
      this.value.children = [new ChildReferenceDTO()]
    }
  }
}
