import Vue from 'vue'
import vuetify from './plugins/vuetify'

import './hooks' // This must be imported before any component
import store from './state/store'

import App from './App.vue'
import router from './router'

import moment from 'moment'
import frLocale from 'moment/locale/fr'

import { rawClient } from './api/client'

import UserUtils from '@/utils/userUtils'
import AccessUtils from '@/utils/accessUtils'
import BookingUtils from '@/utils/bookingUtils'
import { SnackbarEntry } from '@/state/snackbar'
import RulesUtils from '@/utils/rulesUtils'
import GeoUtils from '@/utils/geoUtils'

import services from './services'

import './styles/theme.scss'
import '@mdi/font/scss/materialdesignicons.scss'
import 'flag-icon-css/sass/flag-icon.scss'
import { HandledError } from '@/types'

moment.updateLocale('fr', frLocale)

Vue.prototype.$users = new UserUtils()
Vue.prototype.$access = new AccessUtils()
Vue.prototype.$booking = new BookingUtils()
Vue.prototype.$rules = new RulesUtils()
Vue.prototype.$geo = new GeoUtils()
Vue.prototype.$moment = moment

Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm: Vue, info: string) {
  if (!err) {
    return
  }
  if (!(err as HandledError).__handled__) {
    let message = err.message
    if (info) {
      message += ' (' + info + ')'
    }

    (err as HandledError).__handled__ = true
    store.commit('snackbar/setSnackbarEntry', {
      title: 'Erreur inattendue',
      icon: 'mdi-alert',
      message: message,
      color: 'error'
    } as SnackbarEntry)
  }
  console.error(err)
}

const provide = {
  ...services
}

rawClient.get('').then((response) => {
  store.commit('init/setApi', response.data === 'Planireza API Ready.')
}).catch((err) => {
  console.error(err)
  store.commit('init/setApi', false)
  store.commit('init/addError', err)
})

new Vue({
  router,
  store,
  provide,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
