import { Feature } from 'geojson'

export enum BookingStatus {
  WAITING = 'WAITING',
  CONFIRMED = 'CONFIRMED'
}

export enum Bookable {
  YES = 'YES',
  ADMIN_ONLY = 'ADMIN_ONLY',
  NO = 'NO'
}

export class BaseDTO {
  id: number = null
}

export class UserExcerpt {
  id: string = null
  firstName?: string = null
  lastName?: string = null
  displayName?: string = null
  phone?: string = null
  email?: string = null
}

export class UserSearch extends UserExcerpt {

}

export class UserDTO extends UserSearch {
  username?: string = null
  admin?: boolean = false
  enabled?: boolean = true
}

export class ReferenceDTO extends BaseDTO {
  ref: string = null
  label: string = null
  children: ChildReferenceDTO[] = [] /* eslint-disable-line no-use-before-define */
}

export class ChildReferenceDTO extends BaseDTO {
  quantity = 1.0
  reference: ReferenceDTO = null
}

export class StoreReferenceDTO extends BaseDTO {
  reference: ReferenceDTO = null
  quantity = 1.0
  computedQuantity?: number
  bookable: Bookable = null
  inherited?: boolean
  listId?: number
  listLabel?: string
}

export class StoreTemplateDTO extends BaseDTO {
  label: string = null
}

export class StoreDTO extends BaseDTO {
  label: string = null
  disabled = false
  admins: UserExcerpt[] = []
  geojsonFeature: Feature = null
}

export class StoreClosureDTO extends BaseDTO {
  fromDate: string = null
  toDate: string = null
}

export class SearchQuery {
  users: UserExcerpt[] = []
  storeUsers: UserExcerpt[] = []
  stores: StoreDTO[] = []
  references: ReferenceDTO[] = []
  statuses: BookingStatus[] = []
  orderId: string | null = null
  client: string | null = null
  fromDate: string | null = null
  toDate: string | null = null
  fromCreatedDate: string | null = null
  toCreatedDate: string | null = null
  fromConfirmationDate: string | null = null
  toConfirmationDate: string | null = null
}

export class StoreReferenceListExcerptDTO extends BaseDTO {
  template = false
  label: string = null
}

export class DurationDTO {
  weeks?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
  prior?: boolean
}

export class CalendarSlotDTO {
  fromDate: string = null
  toDate: string = null
  lastDate: string = null
  allDates: string[] = null
  beforeDate: string = null
  duration: DurationDTO = null
}

export class ReferenceQuantityDTO extends BaseDTO {
  reference: ReferenceDTO = null
  quantity: number = null
}

export class BookingDTO extends BaseDTO {
  fromDate: string = null
  toDate: string = null
  confirmationDate: string = null
  references: ReferenceQuantityDTO[] = []
  store: StoreDTO = null
  user: UserDTO = null
  client: string = null
  clientPhone: string = null
  orderId: string = null
  notes: string = null
  status: BookingStatus = null
}

export class CalendarSlotDetailDTO {
  calendarSlot: CalendarSlotDTO = null
  availableReferences: ReferenceQuantityDTO[] = []
  overbookedReferences: ReferenceQuantityDTO[] = []
  hasBookings = false
  bookings: BookingDTO[] = []
}

export class TransientQueryDTO {
  excludeBookingIds: number[] = null
  references: ReferenceQuantityDTO[] = null
  fromDate: string = null
  toDate: string = null
}
