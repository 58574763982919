

































import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import InitStatus from '@/components/init/InitStatus.vue'

const InitNs = namespace('init')

@Component({
  components: { InitStatus }
})
export default class Init extends Vue {
  @InitNs.Getter
  init: boolean

  @InitNs.Getter
  initOk: boolean

  @InitNs.Getter
  initFailed: boolean

  @InitNs.State
  auth: boolean

  @InitNs.State
  api: boolean

  get toolbarColor (): string {
    if (this.initFailed) {
      return 'error'
    } else if (this.initOk) {
      return 'success'
    } else {
      return 'secondary'
    }
  }
}
