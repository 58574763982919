










import Vue from 'vue'
import { StoreDTO } from '@/api/dto'
import { Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'

const Data = namespace('data')

@Component
export default class FavoriteButton extends Vue {
  @Prop({ type: Object, required: true })
  value: StoreDTO

  @Data.Getter('favoriteStoreIds')
  favoriteStoreIds: number[]

  @Data.Mutation('addFavoriteStoreId')
  addFavoriteStoreId: (favoriteStoreId: number) => void

  @Data.Mutation('removeFavoriteStoreId')
  removeFavoriteStoreId: (favoriteStoreId: number) => void

  @Data.Action('saveFavoriteStoreIds')
  saveFavoriteStoreIds: () => void

  favoriteClicked (): void {
    this.setFavorite(!this.favorite)
  }

  get favorite (): boolean {
    return this.favoriteStoreIds.indexOf(this.value.id) >= 0
  }

  setFavorite (favorite: boolean): void {
    if (favorite) {
      this.addFavoriteStoreId(this.value.id)
    } else {
      this.removeFavoriteStoreId(this.value.id)
    }
    this.saveFavoriteStoreIds()
  }
}
