













































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import ReferenceChildren from '@/components/references/ReferenceChildren.vue'
import { StoreReferenceDTO } from '@/api/dto'

@Component({
  components: { ReferenceChildren }
})
export default class StoreReference extends Vue {
  @Prop({ type: Object })
  value: StoreReferenceDTO

  @Prop({ type: Boolean })
  edit: boolean

  computedQuantityEnabled: boolean = null

  @Watch('pack', { immediate: true })
  onPackChanged (value: boolean): void {
    this.computedQuantityEnabled = value
  }

  @Watch('computedQuantityEnabled')
  onComputedQuantityEnabledChanged (value: boolean): void {
    if (value === false) {
      this.value.quantity = this.value.computedQuantity
    } else {
      this.value.quantity = undefined
    }
  }

  get computedQuantityMismatch (): boolean {
    return this.pack &&
      this.value.quantity !== undefined &&
      this.value.computedQuantity !== undefined &&
      this.value.computedQuantity !== this.value.quantity
  }

  get pack (): boolean {
    return this.value.reference.children && this.value.reference.children.length > 0
  }
}
