import libphonenumber from 'google-libphonenumber'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export const DEFAULT_REGION_CODES = ['FR', 'BE']

export function getPhoneNumberRegion (v?: string | null, ...regionCodes: string[]): string | null {
  if (!regionCodes || !regionCodes.length) {
    regionCodes = DEFAULT_REGION_CODES
  }

  if (v && v.startsWith('00')) {
    v = '+' + v.substr(2)
  }

  for (const regionCode of regionCodes) {
    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(v, regionCode)
      if (phoneUtil.isValidNumberForRegion(parsedNumber, regionCode)) {
        return regionCode
      }
    } catch (e) {
      // Do nothing
    }
  }
  return null
}

export function formatPhoneNumber (v?: string | null, ...regionCodes: string[]): string | null {
  if (!v) {
    return null
  }

  const region = getPhoneNumberRegion(v, ...regionCodes)
  if (!region) {
    return v
  }

  const parsedNumber = phoneUtil.parseAndKeepRawInput(v, region)
  return phoneUtil.format(parsedNumber, libphonenumber.PhoneNumberFormat.INTERNATIONAL)
}
