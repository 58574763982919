





















































































import Vue from 'vue'
import Component from 'vue-class-component'
import client from '@/api/client'
import StoreDetails from '@/components/stores/StoreDetails.vue'
import vuexStore from '@/state/store'
import { StoreClosureDTO, StoreDTO } from '@/api/dto'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import { StorePermission } from '@/utils/accessUtils'
import moment from 'moment'
import StoreClosure from '@/components/stores/StoreClosure.vue'
import StoreClosureLabel from '@/components/stores/StoreClosureLabel.vue'
import { namespace } from 'vuex-class'
import StoreLabel from '@/components/stores/StoreLabel.vue'
import { VForm } from '@/types'

const Loading = namespace('loading')

@Component({
  components: { ToolbarButtons, StoreClosure, StoreClosureLabel, StoreLabel }
})
export default class StoreClosures extends Vue {
  @Loading.Getter
  loading: boolean

  store: StoreDTO = null
  edit = false

  closures: StoreClosureDTO[] = []
  removedClosures: Set<StoreClosureDTO> = new Set<StoreClosureDTO>()

  async created (): Promise<void> {
    vuexStore.commit('loading/startLoading', StoreDetails.name)

    try {
      const response = await Promise.all([
        client.get(`stores/${this.$route.params.id}`),
        client.get(`stores/${this.$route.params.id}/closures`)
      ])

      this.store = response[0].data
      this.closures = response[1].data

      if (!this.$access.hasStorePermission(this.store, StorePermission.manageClosures)) {
        await this.$router.push({ name: 'StoresClosures' })
      }
    } catch (err) {
      Vue.config.errorHandler(err, this, null)
      throw err
    } finally {
      vuexStore.commit('loading/stopLoading', StoreDetails.name)
    }
  }

  get visibleClosures (): StoreClosureDTO[] {
    return this.closures.filter((store) => !this.removedClosures.has(store))
  }

  allowedFromDates (closure: StoreClosureDTO, date: string): boolean {
    if (closure.toDate) {
      const fromMoment = moment(date)
      const toMoment = moment(closure.toDate)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  allowedToDates (closure: StoreClosureDTO, date: string): boolean {
    if (closure.fromDate) {
      const fromMoment = moment(closure.fromDate)
      const toMoment = moment(date)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  async submit (): Promise<void> {
    if ((this.$refs.form as VForm).validate()) {
      const closures: StoreClosureDTO[] = []
      for (const closure of this.removedClosures) {
        await client.delete(`stores/${this.$route.params.id}/closures/${closure.id}`)
      }

      for (const closure of this.visibleClosures) {
        let response
        if (closure.id !== undefined && closure.id !== null) {
          response = await client.put(`stores/${this.$route.params.id}/closures/${closure.id}`, closure)
        } else {
          response = await client.post(`stores/${this.$route.params.id}/closures`, closure)
        }
        closures.push(response.data)
      }

      this.edit = false

      this.removedClosures = new Set<StoreClosureDTO>()
      this.closures = closures
    }
  }

  async cancel (): Promise<void> {
    const response = await client.get(`stores/${this.$route.params.id}/closures`)
    this.closures = response.data

    this.edit = false
  }

  removeClosure (closure: StoreClosureDTO): void {
    if (closure.id === undefined || closure.id === null) {
      const index = this.closures.indexOf(closure)
      if (index > -1) {
        this.closures.splice(index, 1)
      }
    } else {
      this.removedClosures.add(closure)
      if (this.closures.length > 0) {
        this.closures.push(this.closures.pop())
      }
    }
  }

  addClosure (): void {
    this.edit = true

    const closure = new StoreClosureDTO()
    this.closures.push(closure)
  }
}
