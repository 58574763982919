






































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

export interface ToolbarButton {
  icon: string,
  edit: boolean,
  submit?: boolean,
  switch?: boolean
  action?: () => void,
  color?: string
}

@Component
export default class ToolbarButtons extends Vue {
  @Prop({ type: Boolean, default: false })
  value: boolean

  @Prop({ type: Array, required: true })
  buttons: ToolbarButton[]

  @Prop({ type: String })
  cardColor?: string

  @Prop({ type: String })
  primaryColor?: string

  @Prop({ type: String })
  accentColor?: string

  edit: boolean

  constructor () {
    super()
    this.edit = this.value
  }

  @Watch('value')
  onValueChanged (newValue: boolean): void {
    this.edit = newValue
  }

  @Watch('edit')
  onEditChanged (newValue: boolean): void {
    this.$emit('input', newValue)
  }

  get editButtons (): ToolbarButton[] {
    return this.buttons.filter(b => b.edit)
  }

  get cardButtons (): ToolbarButton[] {
    return this.buttons.filter(b => !b.edit)
  }

  handleClick (button: ToolbarButton): void {
    if (button.switch) {
      this.edit = !this.edit
    }

    if (button.action) {
      button.action()
    }
  }

  get dCardColor (): string {
    return this.cardColor || 'primary'
  }

  get dPrimaryColor (): string {
    return this.primaryColor || 'primary'
  }

  get dAccentColor (): string {
    return this.accentColor || 'accent'
  }

  getColor (button: ToolbarButton): string {
    if (button.color) {
      return button.color
    }

    if (!this.edit) {
      return this.dCardColor
    } else {
      if (button.submit || button.switch) {
        return this.dPrimaryColor
      }
      return this.dAccentColor
    }
  }
}
