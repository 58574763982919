import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue.lighten1,
        secondary: colors.blueGrey.lighten2,
        accent: colors.orange.darken1,
        error: colors.red.darken1
      }
    }
  }
})

export default vuetify
