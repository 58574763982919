





























































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreDTO } from '@/api/dto'
import { MenuEntry } from '@/state/menu'
import { namespace } from 'vuex-class'
import BookingListTile from '@/components/bookings/BookingListTile.vue'
import { Watch } from 'vue-property-decorator'
import FavoriteButton from '@/components/bookings/FavoriteButton.vue'

const Data = namespace('data')
const Loading = namespace('loading')
const Menu = namespace('menu')

@Component({
  components: { FavoriteButton, BookingListTile }
})
export default class Bookings extends Vue {
  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Loading.Getter
  loading: boolean

  @Data.Getter('stores')
  stores: StoreDTO[]

  @Data.Getter('favoriteStores')
  favoriteStores: StoreDTO[]

  displayDisabled = false
  favoritesOnly = false
  storeFilter = ''

  created (): void {
    this.setMenuEntries([{
      label: 'Mes réservations',
      icon: 'mdi-archive',
      action: () => this.$router.push({ name: 'Me' })
    }])
  }

  async onStoreClicked (store: StoreDTO): Promise<void> {
    await this.$router.push({ name: 'StoreBookings', params: { storeId: '' + store.id } })
  }

  @Watch('stores', { immediate: true })
  onStoresChanged (): void {
    this.favoritesOnly = this.favoriteStores.length > 0
  }

  @Watch('favoriteStores')
  onFavoriteStoresChanged (): void {
    if (this.favoriteStores.length <= 0) {
      this.favoritesOnly = false
    }
  }

  get favoritesDisabled (): boolean {
    return this.favoriteStores.length <= 0
  }

  get displayedStores (): StoreDTO[] {
    let displayedStores = this.favoritesOnly ? this.favoriteStores : this.stores
    if (!this.displayDisabled) {
      displayedStores = displayedStores.filter((store) => !store.disabled)
    }
    if (this.storeFilter) {
      displayedStores = displayedStores.filter((store) => {
        const storeFilterLower = this.storeFilter.toLowerCase()

        let label = store.label
        if (label) {
          label = label.toLowerCase()
        }

        const postCode = this.$geo.cleanPostcode(store)
        let cleanCity = this.$geo.cleanCity(store)
        if (cleanCity) {
          cleanCity = cleanCity.toLowerCase()
        }

        return label?.includes(storeFilterLower) ||
          postCode?.includes(storeFilterLower) ||
          cleanCity?.includes(storeFilterLower) ||
          store.admins.map(u => this.$users.userLabel(u).toLowerCase()).join(', ').includes(storeFilterLower)
      })
    }
    return displayedStores
  }
}
