import { render, staticRenderFns } from "./StoreDetails.vue?vue&type=template&id=5190a0d5&scoped=true&"
import script from "./StoreDetails.vue?vue&type=script&lang=ts&"
export * from "./StoreDetails.vue?vue&type=script&lang=ts&"
import style0 from "./StoreDetails.vue?vue&type=style&index=0&id=5190a0d5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5190a0d5",
  null
  
)

export default component.exports