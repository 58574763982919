











































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment'
import client from '@/api/client'
import { BookingDTO, BookingStatus, CalendarSlotDetailDTO, ReferenceQuantityDTO, StoreDTO } from '@/api/dto'
import BookingConfirmDialog from '@/components/bookings/BookingConfirmDialog.vue'

@Component({
  components: { BookingConfirmDialog }
})
export default class SlotBookings extends Vue {
  @Prop({ type: Object, required: true })
  value: CalendarSlotDetailDTO

  @Prop({ type: Array, required: true })
  storeAvailableReferences: ReferenceQuantityDTO[]

  @Prop({ type: Object, required: true })
  store: StoreDTO

  @Prop({ type: String, default: 'secondary' })
  toolbarColor: string

  @Prop({ type: String, default: 'darken-1' })
  toolbarClass: string

  @Prop({ type: String, default: 'LL' })
  momentFormat: string

  @Prop({ type: Boolean, default: true })
  availableLinks: boolean

  confirmDialog = false
  confirmDialogBooking: BookingDTO = null

  openConfirmDialog (booking: BookingDTO): void {
    this.confirmDialogBooking = booking
    this.confirmDialog = true
  }

  get fromMoment (): moment.Moment {
    return moment(this.value.calendarSlot.fromDate, 'YYYY-MM-DD')
  }

  get isRange (): boolean {
    return this.toMoment.isAfter(moment(this.fromMoment).add(1, 'day'))
  }

  get toMoment (): moment.Moment {
    return moment(this.value.calendarSlot.lastDate)
  }

  getEffectiveToDate (booking: BookingDTO): string {
    let toDate = booking.toDate
    if (!toDate) {
      toDate = this.value.calendarSlot.lastDate
    }
    return toDate
  }

  get availableReferences (): ReferenceQuantityDTO[] {
    return this.value.availableReferences ? this.value.availableReferences : this.storeAvailableReferences
  }

  async confirm (booking: BookingDTO, data: { orderId: string }): Promise<void> {
    this.confirmDialog = false
    booking.orderId = data.orderId
    await this.setStatus(booking, BookingStatus.CONFIRMED)
  }

  async cancel (booking: BookingDTO): Promise<void> {
    await this.setStatus(booking, BookingStatus.WAITING)
  }

  async setStatus (booking: BookingDTO, status: BookingStatus): Promise<void> {
    if (!booking.fromDate) {
      booking.fromDate = this.value.calendarSlot.fromDate
    }
    booking.status = status

    const response = await client.put(`stores/${this.$route.params.storeId}/bookings/${booking.id}`, booking)
    const newBooking = response.data

    for (let i = 0; i++; i < this.value.bookings.length) {
      const existingBooking = this.value.bookings[i]
      if (existingBooking.id === newBooking.id) {
        this.value.bookings.splice(i, 1, newBooking)
      }
    }

    this.$emit('status', this.value)
  }

  async remove (booking: BookingDTO): Promise<void> {
    await this.doRemove(booking)
  }

  async doRemove (booking: BookingDTO): Promise<void> {
    await client.delete(`stores/${this.$route.params.storeId}/bookings/${booking.id}`)
    this.$emit('remove', this.value)
  }
}
