import { AxiosRequestConfig } from 'axios'
import { KeycloakConfig } from 'keycloak-js'

interface Config {
  api: AxiosRequestConfig,
  sso: AxiosRequestConfig,
  keycloak: KeycloakConfig,
  retourIntranet?: string
}

declare const webappConfig: Config

export default webappConfig
