























































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreReferenceListExcerptDTO } from '@/api/dto'
import { Prop, Watch } from 'vue-property-decorator'
import client from '@/api/client'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'

@Component({
  components: { ToolbarButtons }
})
export default class StoreReferenceList extends Vue {
  @Prop({ type: Number })
  storeId: number

  @Prop({ type: Array })
  items: StoreReferenceListExcerptDTO[]

  @Prop({ type: Object })
  value: StoreReferenceListExcerptDTO

  @Prop({ type: String, default: 'stores' })
  endpointKey: string

  edit = false
  selection: StoreReferenceListExcerptDTO = null

  @Watch('value', { immediate: true })
  onValueChanged (value: StoreReferenceListExcerptDTO): void {
    this.selection = value
  }

  async cancel (): Promise<void> {
    this.edit = false
    const response = await client.get(`${this.endpointKey}/${this.$route.params.id}/store-reference-list/parent`)

    const referenceList: StoreReferenceListExcerptDTO = response.data
    this.selection = referenceList
  }

  async submit (): Promise<void> {
    if (this.selection) {
      const response = await client.put(`${this.endpointKey}/${this.$route.params.id}/store-reference-list/parent`, { id: this.selection.id })

      const storeReferenceList: StoreReferenceListExcerptDTO = response.data
      this.selection = storeReferenceList
    } else {
      const response = await client.delete(`${this.endpointKey}/${this.$route.params.id}/store-reference-list/parent`)

      const storeReferenceList: StoreReferenceListExcerptDTO = response.data
      this.selection = storeReferenceList
    }
    this.$emit('input', this.selection)

    this.edit = false
  }
}
