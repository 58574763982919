













































































import Vue from 'vue'
import Component from 'vue-class-component'
import { UserDTO } from '@/api/dto'
import client from '@/api/client'
import vuexStore from '@/state/store'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import { namespace } from 'vuex-class'
import { unparse } from 'papaparse'

const Loading = namespace('loading')

@Component({
  components: { ToolbarButtons }
})
export default class Users extends Vue {
  @Loading.Getter
  loading: boolean

  userFilter: string = null
  users: UserDTO[] = []

  get displayedUsers (): UserDTO[] {
    if (this.userFilter) {
      return this.users.filter((user) => this.$users.userLabel(user).toLowerCase().indexOf(this.userFilter.toLowerCase()) > -1)
    } else {
      return this.users
    }
  }

  async created (): Promise<void> {
    vuexStore.commit('loading/startLoading', Users.name)

    try {
      const response = await client.get('users')
      const data: UserDTO[] = response.data
      this.users = data
    } catch (err) {
      Vue.config.errorHandler(err, null, null)
      throw err
    } finally {
      vuexStore.commit('loading/stopLoading', Users.name)
    }
  }

  async addUser (): Promise<void> {
    await this.$router.push({ name: 'User' })
  }

  get usersMailto (): string[] {
    return this.users.filter(u => !this.$users.isCurrentUser(u)).map(u => u.email).filter(email => !!email)
  }

  exportUsers (): void {
    const csvData = unparse({
      fields: ['enabled', 'username', 'firstName', 'lastName', 'phone', 'email'],
      data: this.users
    }, { delimiter: ';' })
    const csvContent = 'data:text/csv;charset=utf-8,' + '\ufeff' + csvData

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'users.csv')
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
  }
}
