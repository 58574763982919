import Vue from 'vue'
import { Module } from 'vuex'

import { RootState } from './store'

import { ReferenceDTO, StoreDTO, StoreTemplateDTO, UserExcerpt } from '@/api/dto'
import client from '@/api/client'

export interface DataState {
  stores: StoreDTO[],
  storeTemplates: StoreTemplateDTO[]
  favoriteStoreIds: number[]
  references: ReferenceDTO[]
  users: UserExcerpt[]
  loaded: boolean
  loading: number
}

const data = {
  namespaced: true,
  state: {
    stores: [],
    storeTemplates: [],
    favoriteStoreIds: [],
    references: [],
    users: [],
    loaded: false,
    loading: 0
  } as DataState,
  mutations: {
    setStores (state: DataState, stores: StoreDTO[]) {
      state.stores = stores
    },
    setStoreTemplates (state: DataState, storeTemplates: StoreTemplateDTO[]) {
      state.storeTemplates = storeTemplates
    },
    setFavoriteStoreIds (state: DataState, favoriteStoreIds: number[]) {
      state.favoriteStoreIds = favoriteStoreIds
    },
    addFavoriteStoreId (state: DataState, favoriteStoreId: number) {
      state.favoriteStoreIds.push(favoriteStoreId)
    },
    removeFavoriteStoreId (state: DataState, favoriteStoreId: number) {
      const idx = state.favoriteStoreIds.indexOf(favoriteStoreId)
      if (idx >= 0) {
        state.favoriteStoreIds.splice(idx, 1)
      }
    },
    setReferences (state: DataState, references: ReferenceDTO[]) {
      state.references = references
    },
    setUsers (state: DataState, users: UserExcerpt[]) {
      state.users = users
    }
  },
  getters: {
    stores (state: DataState) {
      return state.stores
    },
    storeTemplates (state: DataState) {
      return state.storeTemplates
    },
    favoriteStoreIds (state: DataState) {
      return state.favoriteStoreIds
    },
    favoriteStores (state: DataState) {
      return state.stores.filter((store) => state.favoriteStoreIds.indexOf(store.id) >= 0)
    },
    references (state: DataState) {
      return state.references
    },
    users (state: DataState) {
      return state.users
    },
    loading (state: DataState) {
      return state.loading
    }
  },
  actions: {
    async loadData ({ dispatch }): Promise<[StoreDTO[], StoreTemplateDTO[], string[], ReferenceDTO[], UserExcerpt[]]> {
      return Promise.all([
        dispatch('loadStores'),
        dispatch('loadStoreTemplates'),
        dispatch('loadFavoriteStoreIds'),
        dispatch('loadReferences'),
        dispatch('loadUsers')
      ])
    },
    async loadStores ({ state, commit }): Promise<StoreDTO[]> {
      state.loading++
      try {
        const response = await client.get('stores')
        const stores: StoreDTO[] = response.data
        commit('setStores', stores)
        return stores
      } finally {
        state.loading--
      }
    },
    async loadStoreTemplates ({ state, commit }): Promise<StoreTemplateDTO[]> {
      state.loading++
      try {
        const response = await client.get('store-templates')
        const storeTemplates: StoreTemplateDTO[] = response.data
        commit('setStoreTemplates', storeTemplates)
        return storeTemplates
      } finally {
        state.loading--
      }
    },
    async loadFavoriteStoreIds ({ state, commit }): Promise<string[]> {
      state.loading++
      try {
        const response = await client.get('stores/favorites')
        const favoriteStoreIds: string[] = response.data
        commit('setFavoriteStoreIds', favoriteStoreIds)
        return favoriteStoreIds
      } finally {
        state.loading--
      }
    },
    async saveFavoriteStoreIds ({ state }): Promise<void> {
      await client.put('stores/favorites', state.favoriteStoreIds)
    },
    async loadReferences ({ state, commit }): Promise<ReferenceDTO[]> {
      state.loading++
      try {
        const response = await client.get('references')
        const references: ReferenceDTO[] = response.data

        const referencesById: { [id: number]: ReferenceDTO } = {}
        for (let i = 0; i < references.length; i++) {
          const reference = references[i]
          referencesById[reference.id] = reference
        }

        for (const reference of references.filter(r => r.children)) {
          for (const childReference of reference.children) {
            childReference.reference = referencesById[childReference.reference.id]
          }
        }

        commit('setReferences', references)
        return references
      } finally {
        state.loading--
      }
    },
    async loadUsers ({ state, commit }): Promise<UserExcerpt[]> {
      state.loading++
      try {
        const response = await client.get('users/excerpt')
        const users: UserExcerpt[] = response.data
        users.forEach(u => {
          u.displayName = Vue.prototype.$users.userLabel(u)
        })
        commit('setUsers', users)
        return users
      } finally {
        state.loading--
      }
    }
  }
} as Module<DataState, RootState>

export default data
