






























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { Inject, Watch } from 'vue-property-decorator'
import { SnackbarEntry } from '@/state/snackbar'
import { MenuEntry } from '@/state/menu'
import { ReferenceDTO, StoreDTO, StoreTemplateDTO, UserExcerpt } from '@/api/dto'
import { StorePermission } from '@/utils/accessUtils'
import { namespace } from 'vuex-class'
import ConnectedAs from '@/components/main/ConnectedAs.vue'
import type { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js'
import type * as auth from '@/services/auth'
import config from '@/config'

const Auth = namespace('auth')
const Data = namespace('data')
const Loading = namespace('loading')
const Menu = namespace('menu')
const Snackbar = namespace('snackbar')
@Component({
  components: { ConnectedAs }
})
export default class Main extends Vue {
  @Inject()
  auth: typeof auth

  @Loading.Getter
  loading: boolean

  loadingVisible = false

  @Auth.Getter
  tokenParsed: KeycloakTokenParsed

  @Auth.Getter
  profile: KeycloakProfile

  @Snackbar.Mutation
  setSnackbarEntry: (entry: SnackbarEntry) => void

  @Snackbar.Getter
  currentSnackbarEntry: SnackbarEntry

  @Auth.Getter
  userId: string

  @Data.Getter
  stores: StoreDTO[]

  @Menu.Getter
  menuEntries: MenuEntry[]

  @Data.Action
  loadData: () => Promise<[StoreDTO[], StoreTemplateDTO[], string[], ReferenceDTO[], UserExcerpt[]]>

  snackbarDisplayed = false

  appDrawerVisible = false

  loadingChangedTimeout: number = null

  @Watch('tokenParsed', { immediate: true })
  async onTokenParsedChanged (tokenParsed: KeycloakTokenParsed): Promise<void> {
    if (tokenParsed) {
      await this.loadData()
    }
  }

  @Watch('loading')
  onLoadingChanged (loading: boolean): void {
    if (loading && !this.loadingChangedTimeout) {
      this.loadingChangedTimeout = window.setTimeout(() => {
        this.loadingVisible = true
      }, 500)
    }
    if (!loading) {
      this.loadingVisible = false
      if (this.loadingChangedTimeout) {
        clearTimeout(this.loadingChangedTimeout)
        this.loadingChangedTimeout = null
      }
    }
  }

  async created (): Promise<void> {
    if (this.$router.currentRoute.name === 'Main') {
      if (!this.tokenParsed) {
        await this.$router.push({ name: 'Login' })
      } else {
        await this.$router.push({ name: 'Bookings' })
      }
    }
  }

  get userStores (): StoreDTO[] {
    return this.stores.filter(s => this.$access.hasStorePermission(s, StorePermission.manageClosures))
  }

  get username (): string {
    if (this.profile) {
      if (this.profile.firstName && this.profile.lastName) {
        return `${this.profile.firstName} ${this.profile.lastName}`
      } else if (this.profile.username) {
        return this.profile.username
      }
      return '(user)'
    }
  }

  get retourIntranet (): string | undefined {
    return config.retourIntranet
  }

  async doIntranet (): Promise<void> {
    if (this.retourIntranet) {
      window.location.href = this.retourIntranet
    }
  }

  async doLogout (): Promise<void> {
    await this.auth.logout()
    if (!this.tokenParsed) {
      await this.$router.push({ name: 'Login' })
    }
  }

  get toolbar (): boolean {
    return !!this.username
  }

  get navigationDrawer (): boolean {
    return !!this.username
  }

  get fillHeight (): boolean {
    return this.$route.meta.layout && this.$route.meta.layout.fillHeight
  }

  @Watch('currentSnackbarEntry')
  onSnackbarEntryChange (entry?: SnackbarEntry | null): void {
    this.snackbarDisplayed = !!entry
  }
}
