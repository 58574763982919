














import Vue from 'vue'
import Component from 'vue-class-component'
import { SnackbarEntry } from '@/state/snackbar'
import { namespace } from 'vuex-class'
import InitComponent from '@/components/init/Init.vue'
import { HandledError } from '@/types'

const Init = namespace('init')
const Snackbar = namespace('snackbar')

@Component({
  components: { Init: InitComponent }
})
export default class App extends Vue {
  @Snackbar.Mutation
  setSnackbarEntry: (entry: SnackbarEntry) => void

  @Init.Getter
  initOk: boolean

  @Init.Getter
  initFailed: boolean

  handleError (err: Error, vm: Vue, info: string): void {
    if (!(err as HandledError).__handled__) {
      let message = err.message
      if (info) {
        message += ' (' + info + ')'
      }
      (err as HandledError).__handled__ = true
      this.setSnackbarEntry({
        icon: 'mdi-alert',
        title: 'Erreur inattendue',
        message,
        color: 'error'
      })
    }
  }
}
