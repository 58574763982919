


















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import StoreAdminsList from '@/components/store-admins-list/StoreAdminsList.vue'
import { Prop } from 'vue-property-decorator'
import { StoreDTO } from '@/api/dto'
import StoreLabel from '@/components/stores/StoreLabel.vue'

@Component({
  components: { StoreAdminsList, StoreLabel }
})
export default class BookingListTile extends Vue {
  @Prop()
  value: StoreDTO

  details = false

  get mapLink (): string {
    return this.$geo.buildMapLink(this.value.geojsonFeature)
  }

  get address (): string | null {
    return this.$geo.cleanAddress(this.value)
  }

  get city (): string | null {
    return this.$geo.cleanCity(this.value)
  }

  get postcode (): string | null {
    return this.$geo.cleanPostcode(this.value)
  }
}
