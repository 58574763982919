import { Module } from 'vuex'

import { RootState } from './store'

export interface MenuEntry {
  label?: string
  icon?: string
  color?: string
  class?: string[]
  action?: () => unknown
}

export interface MenuState {
  entries: MenuEntry[],
}

const snackbar = {
  namespaced: true,
  state: {
    entries: []
  } as MenuState,
  mutations: {
    setMenuEntries (state: MenuState, entries: MenuEntry[]) {
      state.entries = entries
    },
    clear (state: MenuState) {
      state.entries = []
    }
  },
  getters: {
    menuEntries (state: MenuState): MenuEntry[] {
      return state.entries
    }
  }
} as Module<MenuState, RootState>

export default snackbar
