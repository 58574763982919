










































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreReferenceDTO } from '@/api/dto'
import { Prop } from 'vue-property-decorator'
import client from '@/api/client'
import StoreReference from './StoreReference.vue'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'

@Component({
  components: { StoreReference, ToolbarButtons }
})
export default class StoreReferences extends Vue {
  @Prop({ type: Array })
  value: StoreReferenceDTO[]

  @Prop({ type: Number })
  storeId: number

  @Prop({ type: String, default: 'stores' })
  endpointKey: string

  edit = false

  get visibleStoreReferences (): StoreReferenceDTO[] {
    if (this.edit) {
      return this.value
    } else {
      return this.value.filter(it => it.listId !== undefined)
    }
  }

  async cancel (): Promise<void> {
    this.edit = false
    const response = await client.get(`${this.endpointKey}/${this.$route.params.id}/references?withComputedQuantity=true`)

    const storeReferences: StoreReferenceDTO[] = response.data
    this.$emit('input', storeReferences)
  }

  async submit (): Promise<void> {
    const response = await client.put(`${this.endpointKey}/${this.$route.params.id}/references?withComputedQuantity=true`, this.value)

    const storeReferences: StoreReferenceDTO[] = response.data
    this.$emit('input', storeReferences)
    this.edit = false
  }
}
