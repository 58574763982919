









































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { UserExcerpt } from '@/api/dto'

@Component
export default class StoreAdminsList extends Vue {
  @Prop({ type: Array, default: (): UserExcerpt[] => [] })
  value: UserExcerpt[]

  @Prop({ type: Boolean, default: true })
  list: boolean

  @Prop({ type: Boolean, default: false })
  details: boolean

  @Prop({ type: Boolean, default: true })
  name: boolean
}
