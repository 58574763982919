import Vue from 'vue'
import Vuex from 'vuex'

import auth, { AuthState } from './auth'
import snackbar, { SnackbarState } from './snackbar'
import menu, { MenuState } from './menu'
import loading, { LoadingState } from './loading'
import data, { DataState } from './data'
import init, { InitState } from './init'

Vue.use(Vuex)

export interface RootState {
  auth: AuthState,
  data: DataState,
  init: InitState,
  snackbar: SnackbarState,
  menu: MenuState,
  loading: LoadingState
}

const store = new Vuex.Store<RootState>({
  state: {} as RootState,
  modules: {
    auth,
    data,
    init,
    snackbar,
    menu,
    loading
  }
})

export default store
