import client from '@/api/client'
import { CalendarSlotDetailDTO, ReferenceQuantityDTO } from '@/api/dto'
import { Moment } from 'moment'

export default class CalendarService {
  async calendarSlotDetails (
    availableReferences: ReferenceQuantityDTO[],
    storeId: string,
    from: Moment | string,
    to: Moment | string): Promise<{
    calendarSlotDetails: CalendarSlotDetailDTO[],
    calendarSlotDetailByDate: Record<string, CalendarSlotDetailDTO>
  }> {
    const response = await client.get(`stores/${storeId}/calendarSlotDetails`, {
      params: {
        from: typeof from === 'string' ? from : from.format('YYYY-MM-DD'),
        to: typeof to === 'string' ? to : to.format('YYYY-MM-DD')
      }
    })

    const calendarSlotDetails: CalendarSlotDetailDTO[] = response.data
    this.sanitizeCalendarSlotDetails(calendarSlotDetails, availableReferences)
    const calendarSlotDetailByDate = this.buildCalendarSlotDetailsByDate(calendarSlotDetails)
    return {
      calendarSlotDetails,
      calendarSlotDetailByDate
    }
  }

  private sanitizeCalendarSlotDetails (calendarSlotDetails: CalendarSlotDetailDTO[], availableReferences: ReferenceQuantityDTO[]) {
    for (const calendarSlotDetail of calendarSlotDetails) {
      if (calendarSlotDetail.availableReferences === undefined) {
        calendarSlotDetail.availableReferences = availableReferences
      }
      if (calendarSlotDetail.bookings === undefined) {
        calendarSlotDetail.bookings = []
      }
    }
  }

  private buildCalendarSlotDetailsByDate (calendarSlotDetails: CalendarSlotDetailDTO[]) {
    const byDate: Record<string, CalendarSlotDetailDTO> = {}

    for (const calendarSlotDetail of calendarSlotDetails) {
      for (const date of calendarSlotDetail.calendarSlot.allDates) {
        byDate[date] = calendarSlotDetail
      }
    }

    return byDate
  }
}
