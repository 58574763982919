











































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { UserDTO, UserExcerpt } from '@/api/dto'
import client from '@/api/client'
import vuexStore from '@/state/store'
import { Prop, Watch } from 'vue-property-decorator'
import { MenuEntry } from '@/state/menu'
import { namespace } from 'vuex-class'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import PhoneNumberField from '@/components/phone-number-field/PhoneNumberField.vue'
import { formatPhoneNumber } from '@/utils/phoneUtils'
import { VForm } from '@/types'

const Data = namespace('data')
const Menu = namespace('menu')

@Component({
  components: { ToolbarButtons, PhoneNumberField }
})
export default class User extends Vue {
  @Prop({ type: String })
  id: string

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Data.Action
  loadUsers: () => Promise<UserExcerpt[]>

  edit = false
  user: UserDTO = null
  submitting = false
  emailSubmit = false
  emailSubmitting = false

  async created (): Promise<void> {
    this.setMenuEntries([{
      label: 'Afficher les utilisateurs',
      icon: 'mdi-account-multiple',
      action: () => this.$router.push({ name: 'Users' })
    }])

    vuexStore.commit('loading/startLoading', User.name)

    try {
      await this.loadUser()
    } catch (err) {
      Vue.config.errorHandler(err, null, null)
      throw err
    } finally {
      vuexStore.commit('loading/stopLoading', User.name)
    }
  }

  @Watch('value', { immediate: true })
  async onValueChanged (): Promise<void> {
    await this.loadUser()
  }

  async loadUser (): Promise<UserDTO> {
    if (this.id !== undefined && this.id !== null) {
      const response = await client.get(`users/${this.id}`)
      const data: UserDTO = response.data
      if (data.phone) {
        data.phone = formatPhoneNumber(data.phone)
      }
      this.user = data
    } else {
      this.user = new UserDTO()
      return Promise.resolve(this.user)
    }
  }

  async submit (): Promise<void> {
    if ((this.$refs.form as VForm).validate()) {
      this.submitting = true
      try {
        let response
        if (this.user.id) {
          response = await client.put(`users/${this.user.id}`, this.user)
        } else {
          response = await client.post('users', this.user)
        }
        this.user = response.data
        await this.loadUsers()
      } finally {
        this.submitting = false
      }

      await this.$router.push({ name: 'Users' })
    }
  }

  async cancel (): Promise<UserDTO> {
    this.submitting = true
    try {
      return await this.loadUser()
    } finally {
      this.submitting = false
    }
  }

  async remove (): Promise<void> {
    this.submitting = true
    try {
      await client.delete(`users/${this.user.id}`)
      await this.loadUsers()
    } finally {
      this.submitting = false
    }

    await this.$router.push({ name: 'Users' })
  }

  async onSendUpdatePasswordEmailClick (): Promise<void> {
    this.emailSubmitting = true
    try {
      await client.post(`users/${this.user.id}/send-update-password-email`)
      this.emailSubmit = true
    } finally {
      this.emailSubmitting = false
    }
  }
}
