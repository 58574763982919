























































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SlotBookings from '@/components/bookings/SlotBookings.vue'
import client from '@/api/client'
import {
  BookingDTO,
  BookingStatus,
  CalendarSlotDetailDTO,
  ReferenceQuantityDTO,
  StoreDTO,
  TransientQueryDTO,
  UserDTO,
  UserExcerpt
} from '@/api/dto'
import moment from 'moment'
import { MenuEntry } from '@/state/menu'
import vuexStore from '@/state/store'
import { Inject, Watch } from 'vue-property-decorator'
import { BookingPermission } from '@/utils/accessUtils'
import cloneDeep from 'lodash/cloneDeep'
import { namespace } from 'vuex-class'
import MenuDatePicker from '@/components/menu-date-picker/MenuDatePicker.vue'
import PhoneNumberField from '@/components/phone-number-field/PhoneNumberField.vue'
import CalendarService from '@/services/calendar'
import { VForm } from '@/types'
import BookingConfirmDialog from '@/components/bookings/BookingConfirmDialog.vue'

const Auth = namespace('auth')
const Data = namespace('data')
const Loading = namespace('loading')
const Menu = namespace('menu')

@Component({
  components: { BookingConfirmDialog, SlotBookings, MenuDatePicker, PhoneNumberField }
})
export default class Booking extends Vue {
  @Data.Getter
  users: UserExcerpt[]

  store: StoreDTO = null
  defaultAvailableReferences: ReferenceQuantityDTO[] = []
  calendarSlotDetail: CalendarSlotDetailDTO = null
  availableReferences: ReferenceQuantityDTO[] = []
  overbookedReferences: ReferenceQuantityDTO[] = []
  overbookedFixDate: null | string = null
  booking: BookingDTO = null
  create = false
  valid = true
  dirty = false
  submitting = false
  loadingUser = false
  confirmDialog = false

  @Loading.Getter
  loading: boolean

  @Auth.Getter
  userId: string

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Inject()
  calendar: CalendarService

  get availableReferencesWithQuantity (): ReferenceQuantityDTO[] {
    return this.availableReferences.filter(ar => ar.quantity > 0)
  }

  async created (): Promise<void> {
    this.setMenuEntries([{
      label: 'Afficher le calendrier',
      icon: 'mdi-calendar',
      action: () => this.back()
    }])

    vuexStore.commit('loading/startLoading', Booking.name)

    try {
      const promises = [
        client.get(`stores/${this.$route.params.storeId}`),
        client.get(`stores/${this.$route.params.storeId}/availableReferences`),
        client.get(`stores/${this.$route.params.storeId}/calendarSlotDetails/${this.$route.params.date}`)
      ]

      const response = await Promise.all(promises)

      const store: StoreDTO = response[0].data
      const defaultAvailableReferences: ReferenceQuantityDTO[] = response[1].data
      const calendarSlotDetail: CalendarSlotDetailDTO = response[2].data

      this.store = store
      this.defaultAvailableReferences = defaultAvailableReferences
      this.calendarSlotDetail = calendarSlotDetail

      if (this.$route.params.id) {
        for (const booking of calendarSlotDetail.bookings) {
          if (booking.id.toString() === this.$route.params.id) {
            this.booking = booking
          }

          if (this.booking) {
            const index = calendarSlotDetail.bookings.indexOf(this.booking)
            calendarSlotDetail.bookings.splice(index, 1)
          }
        }
      }

      if (!this.booking) {
        this.create = true
        this.booking = new BookingDTO()
        this.booking.fromDate = this.calendarSlotDetail.calendarSlot.fromDate
        this.booking.toDate = null
        await this.onUsersChanged(this.users)
      }

      await this.reloadAvailableReferences()
    } catch (err) {
      Vue.config.errorHandler(err, null, null)
      throw err
    } finally {
      vuexStore.commit('loading/stopLoading', Booking.name)
    }
  }

  allowedFromDates (date: string): boolean {
    const dateMoment = moment(date)
    const weekday = dateMoment.weekday()
    return weekday !== 5 && weekday !== 6
  }

  allowedToDates (date: string): boolean {
    if (this.booking.fromDate) {
      const toMoment = moment(date)
      const fromMoment = moment(this.calendarSlotDetail.calendarSlot.toDate)

      return toMoment.isSameOrAfter(fromMoment)
    }
    return true
  }

  @Watch('users', { immediate: true })
  async onUsersChanged (users: UserExcerpt[]): Promise<void> {
    if (this.booking && !this.booking.user) {
      const user = users.find(u => u.id === this.userId)
      this.booking.user = user
      if (user) {
        const response = await client.get(`users/${user.id}`)
        this.booking.user = response.data
      }
    }
  }

  onFromDateInputChanged (): void {
    this.dirty = true
    this.booking.toDate = null
  }

  @Watch('booking.fromDate')
  async onFromDateChanged (fromDate: string): Promise<void> {
    const response = await client.get(`stores/${this.$route.params.storeId}/calendarSlotDetails/${fromDate}`)
    this.calendarSlotDetail = response.data
    await this.reloadAvailableReferences()
  }

  @Watch('booking.toDate')
  async onToDateChanged (): Promise<void> {
    await this.reloadAvailableReferences()
  }

  async onUserSelectInput (user: UserDTO): Promise<void> {
    this.dirty = true
    if (user) {
      this.loadingUser = true
      try {
        const response = await client.get(`users/${user.id}`)
        this.booking.user = response.data
      } finally {
        this.loadingUser = false
      }
    }
  }

  get canWriteUser (): boolean {
    return this.$access.hasBookingPermission(this.store, this.booking, BookingPermission.writeUser)
  }

  get canWriteFromDate (): boolean {
    return this.$access.hasBookingPermission(this.store, this.booking, BookingPermission.writeFromDate)
  }

  get canWriteToDate (): boolean {
    return this.$access.hasBookingPermission(this.store, this.booking, BookingPermission.writeToDate)
  }

  get canWrite (): boolean {
    return this.$access.hasBookingPermission(this.store, this.booking, BookingPermission.write)
  }

  get canWriteNotes (): boolean {
    return this.$access.hasBookingPermission(this.store, this.booking, BookingPermission.writeNotes)
  }

  get canReadNotes (): boolean {
    return this.$access.hasBookingPermission(this.store, this.booking, BookingPermission.readNotes)
  }

  get fromMoment (): moment.Moment {
    return moment(this.booking.fromDate, 'YYYY-MM-DD')
  }

  get effectiveToDate (): string {
    let toDate = this.booking.toDate
    if (!toDate) {
      toDate = this.calendarSlotDetail.calendarSlot.lastDate
    }
    return toDate
  }

  get toDatePlaceholder (): string {
    return moment(this.effectiveToDate, 'YYYY-MM-DD').format('LL')
  }

  async referenceClicked (reference: ReferenceQuantityDTO): Promise<void> {
    this.dirty = true
    reference.quantity -= 1

    let found = false
    for (const bookingReference of this.booking.references) {
      if (bookingReference.reference.id === reference.reference.id) {
        bookingReference.quantity += 1
        found = true
        break
      }
    }

    if (!found) {
      const referenceQuantity = new ReferenceQuantityDTO()
      referenceQuantity.reference = reference.reference
      referenceQuantity.quantity = 1
      this.booking.references.push(referenceQuantity)
    }

    await this.reloadAvailableReferences()
  }

  async bookingReferenceClicked (reference: ReferenceQuantityDTO): Promise<void> {
    this.dirty = true
    reference.quantity -= 1
    const indexOf = this.booking.references.indexOf(reference)
    if (reference.quantity <= 0 && indexOf > -1) {
      this.booking.references.splice(indexOf, 1)
    }

    for (const availableReference of this.availableReferences) {
      if (availableReference.reference.id === reference.reference.id) {
        availableReference.quantity += 1
        break
      }
    }

    await this.reloadAvailableReferences()
  }

  async back (): Promise<void> {
    await this.$router.push({
      name: 'StoreBookings',
      params: { storeId: this.$route.params.storeId, date: this.$route.params.date }
    })
  }

  async save (): Promise<void> {
    if ((this.$refs.form as VForm).validate()) {
      this.submitting = true
      try {
        if (this.create) {
          await client.post(`stores/${this.$route.params.storeId}/bookings`, this.booking)
        } else {
          await client.put(`stores/${this.$route.params.storeId}/bookings/${this.$route.params.id}`, this.booking)
        }
      } finally {
        this.submitting = false
      }

      await this.back()
    }
  }

  async remove (): Promise<void> {
    this.submitting = true
    try {
      await client.delete(`stores/${this.$route.params.storeId}/bookings/${this.$route.params.id}`)
    } finally {
      this.submitting = false
    }

    await this.back()
  }

  async confirm (data: {orderId: string}): Promise<void> {
    this.confirmDialog = false
    this.booking.orderId = data.orderId
    await this.setStatus(BookingStatus.CONFIRMED)
  }

  async cancel (): Promise<void> {
    await this.setStatus(BookingStatus.WAITING)
  }

  fixOverbookedReferencesWithDate (): void {
    this.booking.toDate = this.overbookedFixDate
  }

  async fixOverbookedReferencesWithReferences (): Promise<void> {
    for (const overbookedReference of this.overbookedReferences) {
      for (const reference of this.booking.references) {
        if (overbookedReference.reference.ref === reference.reference.ref) {
          reference.quantity += overbookedReference.quantity
          if (reference.quantity <= 0) {
            const i = this.booking.references.indexOf(reference)
            this.booking.references.splice(i, 1)
          }
        }
      }
    }

    await this.reloadAvailableReferences()
  }

  async setStatus (status: BookingStatus): Promise<void> {
    this.submitting = true
    try {
      this.booking.status = status
      const response = await client.put(`stores/${this.$route.params.storeId}/bookings/${this.booking.id}`, this.booking)
      this.booking = response.data
    } finally {
      this.submitting = false
    }

    await this.back()
  }

  async reloadAvailableReferences (): Promise<void> {
    const transientQuery = {
      references: this.booking.references,
      fromDate: this.booking.fromDate,
      toDate: this.booking.toDate
    } as TransientQueryDTO

    if (this.booking.id !== undefined && this.booking.id !== null) {
      transientQuery.excludeBookingIds = [this.booking.id]
    }

    const response = await client.post(`stores/${this.$route.params.storeId}/calendarSlotDetails/transient`, transientQuery)

    const calendarSlotDetails: CalendarSlotDetailDTO[] = response.data
    const availableReferencesMap: Map<string, ReferenceQuantityDTO> = new Map<string, ReferenceQuantityDTO>()
    const overbookedReferencesMap: Map<string, ReferenceQuantityDTO> = new Map<string, ReferenceQuantityDTO>()
    let overbookedFixDate: string | null = null

    for (const referenceQuantity of this.defaultAvailableReferences) {
      availableReferencesMap.set(referenceQuantity.reference.ref, cloneDeep(referenceQuantity))
    }

    for (const calendarSlotDetail of calendarSlotDetails) {
      if (calendarSlotDetail.overbookedReferences && calendarSlotDetail.overbookedReferences.length > 0) {
        if (!overbookedFixDate) {
          overbookedFixDate = calendarSlotDetail.calendarSlot.beforeDate
        }
        for (const referenceQuantity of calendarSlotDetail.overbookedReferences) {
          let slotReferenceQuantity = overbookedReferencesMap.get(referenceQuantity.reference.ref)
          if (!slotReferenceQuantity) {
            slotReferenceQuantity = referenceQuantity
          }
          overbookedReferencesMap.set(slotReferenceQuantity.reference.ref, slotReferenceQuantity)
        }
      }

      if (calendarSlotDetail.availableReferences) {
        const slotReferencesMap: Map<string, ReferenceQuantityDTO> = new Map<string, ReferenceQuantityDTO>()

        for (const referenceQuantity of calendarSlotDetail.availableReferences) {
          slotReferencesMap.set(referenceQuantity.reference.ref, cloneDeep(referenceQuantity))
        }

        for (const defaultReferenceQuantity of availableReferencesMap.values()) {
          const slotReferenceQuantity = slotReferencesMap.get(defaultReferenceQuantity.reference.ref)
          if (!slotReferenceQuantity || slotReferenceQuantity.quantity <= 0) {
            availableReferencesMap.delete(defaultReferenceQuantity.reference.ref)
          } else {
            const availableReferenceQuantity = availableReferencesMap.get(defaultReferenceQuantity.reference.ref)
            slotReferenceQuantity.quantity = Math.min(slotReferenceQuantity.quantity, availableReferenceQuantity.quantity)
            availableReferencesMap.set(defaultReferenceQuantity.reference.ref, slotReferenceQuantity)
          }
        }
      }
    }

    const availableReferences = []
    for (const referenceQuantity of availableReferencesMap.values()) {
      if (referenceQuantity.quantity > 0) {
        availableReferences.push(referenceQuantity)
      }
    }
    this.availableReferences = availableReferences

    const overbookedReferences = []
    for (const referenceQuantity of overbookedReferencesMap.values()) {
      if (referenceQuantity.quantity < 0) {
        overbookedReferences.push(referenceQuantity)
      }
    }
    this.overbookedReferences = overbookedReferences
    this.overbookedFixDate = overbookedFixDate
  }
}
