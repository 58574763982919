
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { UserExcerpt } from '@/api/dto'
import { Prop, Watch } from 'vue-property-decorator'
import client from '@/api/client'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import StoreAdminsList from '@/components/store-admins-list/StoreAdminsList.vue'

@Component({
  components: { ToolbarButtons, StoreAdminsList }
})
export default class StoreAdmins extends Vue {
  @Prop({ type: Number })
  storeId: number

  @Prop({ type: Array })
  value: UserExcerpt[]

  @Prop({ type: Array })
  items: UserExcerpt[]

  edit = false
  admins: UserExcerpt[] = null

  selectedUser: UserExcerpt = null

  @Watch('value', { immediate: true })
  onValueChanged (value: UserExcerpt[]): void {
    this.admins = value
  }

  @Watch('selectedUser')
  onUserSelectedChange (selectedUser: UserExcerpt): void {
    if (selectedUser) {
      this.admins.push(selectedUser)
    }
    this.selectedUser = null
  }

  remove (index: number): void {
    this.admins.splice(index, 1)
  }

  async cancel (): Promise<void> {
    this.edit = false
    const response = await client.get(`stores/${this.$route.params.id}/admins`)

    this.admins = response.data
  }

  async submit (): Promise<void> {
    const response = await client.put(`stores/${this.$route.params.id}/admins`, this.admins)

    this.admins = response.data
    this.$emit('input', this.admins)

    this.edit = false
  }
}
