






import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreClosureDTO } from '@/api/dto'
import moment from 'moment'
import MenuDatePicker from '@/components/menu-date-picker/MenuDatePicker.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { MenuDatePicker }
})
export default class StoreClosureLabel extends Vue {
  @Prop({ type: Object })
  value: StoreClosureDTO

  @Prop({ type: String, default: 'LL' })
  format: string

  get fromLabel (): string {
    if (this.value.fromDate) {
      return moment(this.value.fromDate, 'YYYY-MM-DD').format(this.format)
    }
  }

  get toLabel (): string {
    if (this.value.toDate) {
      return moment(this.value.toDate, 'YYYY-MM-DD').format(this.format)
    }
  }
}
