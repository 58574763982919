




































































import Vue from 'vue'
import Component from 'vue-class-component'
import Reference from './Reference.vue'
import { ChildReferenceDTO, ReferenceDTO } from '@/api/dto'
import client from '@/api/client'
import cloneDeep from 'lodash/cloneDeep'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

let currentId = 0

const Data = namespace('data')
const Loading = namespace('loading')

@Component({
  components: { Reference, ToolbarButtons }
})
export default class References extends Vue {
  newReferences: Set<ReferenceDTO> = new Set()
  deletedReferences: Set<ReferenceDTO> = new Set()

  @Data.Action
  loadReferences: () => Promise<ReferenceDTO[]>

  @Data.Getter('references')
  vuexReferences: ReferenceDTO[]

  references: ReferenceDTO[] = []

  @Loading.Getter
  loading: boolean

  edit = false

  @Watch('vuexReferences', { immediate: true })
  onVuexReferencesChanged (references: ReferenceDTO[]): void {
    this.references = references
  }

  async created (): Promise<void> {
    if (!this.$access.isAdmin()) {
      await this.$router.push({ name: 'Main' })
    }
  }

  addReference (): void {
    this.edit = true

    const view = new ReferenceDTO()
    view.id = --currentId
    this.newReferences.add(view)
    this.references.push(view)
  }

  get visibleReferences (): ReferenceDTO[] {
    return this.references.filter((reference) => !this.deletedReferences.has(reference))
  }

  onDeleted (reference: ReferenceDTO): void {
    if (this.newReferences.has(reference)) {
      this.newReferences.delete(reference)
      const indexOf = this.references.indexOf(reference)
      if (indexOf >= 0) {
        this.references.splice(indexOf, 1)
      }
    } else {
      this.deletedReferences.add(reference)
      if (this.references.length > 0) {
        this.references.push(this.references.pop())
      }
    }
    this.$forceUpdate()
  }

  async cancel (): Promise<void> {
    await this.loadReferences()
    this.edit = false
  }

  async submit (): Promise<void> {
    const referencesIds: { [id: number]: ReferenceDTO } = {}
    const toCreate: ReferenceDTO[] = []
    const toUpdate: ReferenceDTO[] = []
    const toDelete: ReferenceDTO[] = []

    for (const reference of this.references) {
      if (reference.id) {
        referencesIds[reference.id] = reference
      }

      if (this.newReferences.has(reference) && this.deletedReferences.has(reference)) {
        const index = this.references.indexOf(reference)
        this.references.splice(index, 1, reference)
      } else if (this.newReferences.has(reference)) {
        toCreate.push(reference)
      } else if (this.deletedReferences.has(reference)) {
        toDelete.push(reference)
      } else {
        toUpdate.push(reference)
      }
    }

    const createPromises = []
    const createChildren: ChildReferenceDTO[][] = []
    for (let toCreateItem of toCreate) {
      // Create new items with their children detached
      createChildren.push(toCreateItem.children)
      toCreateItem = cloneDeep(toCreateItem)
      delete toCreateItem.children
      createPromises.push(client.post('references', toCreateItem))
    }

    const createResponses = await Promise.all(createPromises)
    for (let i = 0; i < createResponses.length; i++) {
      const createResponse = createResponses[i]
      const createdReference = createResponse.data
      const toCreateReferenceVM = toCreate[i]

      createdReference.children = createChildren[i]
      referencesIds[createdReference.id] = createdReference
      referencesIds[toCreateReferenceVM.id] = createdReference

      const index = this.references.indexOf(toCreateReferenceVM)
      this.references.splice(index, 1, createdReference)

      if (createdReference.children) {
        toUpdate.push(createdReference)
      }
    }

    const updatePromises = []
    for (const toUpdateItem of toUpdate) {
      if (toUpdateItem.children) {
        // Replace temporary ids with created ones.
        for (const child of toUpdateItem.children) {
          const createdReference = referencesIds[child.reference.id]
          if (createdReference !== undefined) {
            child.reference = createdReference
          }
        }
      }

      updatePromises.push(client.put(`references/${toUpdateItem.id}`, toUpdateItem))
    }
    const updateResponses = await Promise.all(updatePromises)
    for (let i = 0; i < updateResponses.length; i++) {
      // Update updated elements references
      const updateResponse = updateResponses[i]
      const updatedReference = updateResponse.data
      const toUpdateReferenceVM = toUpdate[i]

      if (updatedReference.children) {
        for (const childReference of updatedReference.children) {
          childReference.reference = referencesIds[childReference.reference.id]
        }
      }

      const index = this.references.indexOf(toUpdateReferenceVM)
      this.references.splice(index, 1, updatedReference)
    }

    const deletePromises = []
    for (const toDeleteItem of toDelete) {
      deletePromises.push(client.delete(`references/${toDeleteItem.id}`))
    }
    const deleteResponses = await Promise.all(deletePromises)
    for (let i = 0; i < deleteResponses.length; i++) {
      const toDeleteReferenceVM = toDelete[i]

      const index = this.references.indexOf(toDeleteReferenceVM)
      this.references.splice(index, 1)
    }

    this.edit = false
  }
}
