































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Store from './Store.vue'
import { StoreDTO, StoreTemplateDTO } from '@/api/dto'
import client from '@/api/client'

import cloneDeep from 'lodash/cloneDeep'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import StoreAdminsList from '@/components/store-admins-list/StoreAdminsList.vue'
import StoreLabel from '@/components/stores/StoreLabel.vue'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import BookingListTile from '@/components/bookings/BookingListTile.vue'

const Data = namespace('data')
const Loading = namespace('loading')

@Component({
  components: { BookingListTile, Store, ToolbarButtons, StoreAdminsList, StoreLabel }
})
export default class Stores extends Vue {
  @Loading.Getter
  loading: boolean

  displayDisabledValue = false

  stores: StoreDTO[] = []
  storeTemplates: StoreTemplateDTO[] = []

  edit = false
  editTemplates = false

  newStores: Set<StoreDTO> = new Set()
  deletedStores: Set<StoreDTO> = new Set()

  newStoreTemplates: Set<StoreTemplateDTO> = new Set()
  deletedStoreTemplates: Set<StoreTemplateDTO> = new Set()

  @Data.Getter('stores')
  vuexStores: StoreDTO[]

  @Data.Getter('storeTemplates')
  vuexStoreTemplates: StoreTemplateDTO[]

  @Data.Action
  loadStores: () => Promise<StoreDTO[]>

  @Data.Action
  loadStoreTemplates: () => Promise<StoreTemplateDTO[]>

  get displayDisabled (): boolean {
    return this.displayDisabledValue || this.edit
  }

  set displayDisabled (value: boolean) {
    this.displayDisabledValue = value
  }

  @Watch('vuexStores', { immediate: true })
  onVuexStoresChange (stores: StoreDTO[]): void {
    this.stores = stores
  }

  @Watch('vuexStoreTemplates', { immediate: true })
  onVuexStoresTemplatesChange (storeTemplates: StoreTemplateDTO[]): void {
    this.storeTemplates = storeTemplates
  }

  async created (): Promise<void> {
    if (!this.$access.isAdmin()) {
      await this.$router.push({ name: 'Main' })
    }
  }

  addStore (): void {
    this.edit = true

    const view: StoreDTO = new StoreDTO()
    this.newStores.add(view)
    this.stores.push(view)
  }

  addStoreTemplate (): void {
    this.editTemplates = true

    const view: StoreTemplateDTO = new StoreTemplateDTO()
    this.newStoreTemplates.add(view)
    this.storeTemplates.push(view)
  }

  onDeleted (store: StoreDTO): void {
    if (this.newStores.has(store)) {
      this.newStores.delete(store)
      const indexOf = this.stores.indexOf(store)
      if (indexOf >= 0) {
        this.stores.splice(indexOf, 1)
      }
    } else {
      this.deletedStores.add(store)
      if (this.stores.length > 0) {
        this.stores.push(this.stores.pop())
      }
    }
  }

  onTemplateDeleted (storeTemplate: StoreTemplateDTO): void {
    if (this.newStoreTemplates.has(storeTemplate)) {
      this.newStoreTemplates.delete(storeTemplate)
      const indexOf = this.storeTemplates.indexOf(storeTemplate)
      if (indexOf >= 0) {
        this.storeTemplates.splice(indexOf, 1)
      }
    } else {
      this.deletedStoreTemplates.add(storeTemplate)
      if (this.storeTemplates.length > 0) {
        this.storeTemplates.push(this.storeTemplates.pop())
      }
    }
  }

  get visibleStores (): StoreDTO[] {
    let visibleStores = this.stores.filter((store) => !this.deletedStores.has(store))
    if (!this.displayDisabled) {
      visibleStores = visibleStores.filter((store) => !store.disabled)
    }
    return visibleStores
  }

  get visibleStoreTemplates (): StoreTemplateDTO[] {
    return this.storeTemplates.filter((storeTemplate) => !this.deletedStoreTemplates.has(storeTemplate))
  }

  async storeClicked (store: StoreDTO): Promise<void> {
    await this.$router.push({ name: 'StoreDetails', params: { id: '' + store.id } })
  }

  async cancel (): Promise<void> {
    await this.loadStores()
    this.edit = false
  }

  async cancelTemplates (): Promise<void> {
    await this.loadStoreTemplates()
    this.editTemplates = false
  }

  async submit (): Promise<void> {
    const toCreate: StoreDTO[] = []
    const toUpdate: StoreDTO[] = []
    const toDelete: StoreDTO[] = []

    for (const store of this.stores) {
      if (this.newStores.has(store) && this.deletedStores.has(store)) {
        const index = this.stores.indexOf(store)
        this.stores.splice(index, 1, store)
      } else if (this.newStores.has(store)) {
        toCreate.push(store)
      } else if (!this.deletedStores.has(store)) {
        toUpdate.push(store)
      } else {
        toDelete.push(store)
      }
    }

    const createPromises = []
    for (const toCreateItem of toCreate) {
      createPromises.push(client.post('stores', toCreateItem))
    }
    const createResponses = await Promise.all(createPromises)
    for (let i = 0; i < toCreate.length; i++) {
      const createResponse = createResponses[i]
      const createdStore = createResponse.data

      const index = this.stores.indexOf(createdStore)
      this.stores.splice(index, 1, createdStore)
    }

    const updatePromises = []
    for (let toUpdateItem of toUpdate) {
      toUpdateItem = cloneDeep(toUpdateItem)
      delete toUpdateItem.admins
      updatePromises.push(client.patch(`stores/headers/${toUpdateItem.id}`, toUpdateItem))
    }
    await Promise.all(updatePromises)

    const deletePromises = []
    for (const toDeleteItem of toDelete) {
      deletePromises.push(client.delete(`stores/${toDeleteItem.id}`))
    }
    await Promise.all(deletePromises)

    await this.cancel()
  }

  async submitTemplates (): Promise<void> {
    const toCreate: StoreTemplateDTO[] = []
    const toUpdate: StoreTemplateDTO[] = []
    const toDelete: StoreTemplateDTO[] = []

    for (const storeTemplate of this.storeTemplates) {
      if (this.newStoreTemplates.has(storeTemplate) && this.deletedStoreTemplates.has(storeTemplate)) {
        const index = this.storeTemplates.indexOf(storeTemplate)
        this.storeTemplates.splice(index, 1, storeTemplate)
      } else if (this.newStoreTemplates.has(storeTemplate)) {
        toCreate.push(storeTemplate)
      } else if (!this.deletedStoreTemplates.has(storeTemplate)) {
        toUpdate.push(storeTemplate)
      } else {
        toDelete.push(storeTemplate)
      }
    }

    const createPromises = []
    for (const toCreateItem of toCreate) {
      createPromises.push(client.post('store-templates', toCreateItem))
    }
    const createResponses = await Promise.all(createPromises)
    for (let i = 0; i < toCreate.length; i++) {
      const createResponse = createResponses[i]
      const createdStore = createResponse.data

      const index = this.storeTemplates.indexOf(createdStore)
      this.storeTemplates.splice(index, 1, createdStore)
    }

    const updatePromises = []
    for (let toUpdateItem of toUpdate) {
      toUpdateItem = cloneDeep(toUpdateItem)
      updatePromises.push(client.patch(`store-templates/${toUpdateItem.id}`, toUpdateItem))
    }
    await Promise.all(updatePromises)

    const deletePromises = []
    for (const toDeleteItem of toDelete) {
      deletePromises.push(client.delete(`store-templates/${toDeleteItem.id}`))
    }
    await Promise.all(deletePromises)

    await this.cancelTemplates()
  }
}
