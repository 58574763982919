


































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { StoreDTO } from '@/api/dto'
import StoreAdmins from '@/components/stores/StoreAdmins.vue'
import StoreLabel from '@/components/stores/StoreLabel.vue'
import debounce from 'lodash/debounce'

import axios from 'axios'
import { Feature } from 'geojson'

interface Adress {
  properties: Record<string, unknown>
}

@Component({
  components: { StoreAdmins, StoreLabel }
})
export default class Store extends Vue {
  @Prop({ type: Object, required: true })
  value: StoreDTO

  @Prop({ type: Boolean, default: false })
  edit: boolean

  @Prop({ type: Boolean, default: false })
  template: boolean

  loadingAdresses = false

  foundAdresses: Adress[] = []

  searchAdress: string = null

  onSearchAdressChangeDebounce = debounce(this.onSearchAdressChangeImpl, 250)

  geojsonFeatureStored: Feature = null
  freeInputFeature: Feature = null
  freeInput = false

  @Watch('searchAdress')
  async onSearchAdressChange (searchAdress: string): Promise<void> {
    this.loadingAdresses = true
    await this.onSearchAdressChangeDebounce(searchAdress)
  }

  @Watch('freeInput')
  onFreeInputChange (): void {
    this.freeInputFeature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [2.3488, 48.8534]
      },
      properties: {
        name: '',
        city: '',
        postcode: '',
        country: ''
      },
      ...(this.geojsonFeatureStored ? this.geojsonFeatureStored : {}),
      ...(this.value.geojsonFeature ? this.value.geojsonFeature : {})
    }
  }

  freeInputValidate (): void {
    this.freeInput = false
    if (this.freeInputFeature.properties.name && this.freeInputFeature.properties.city && this.freeInputFeature.properties.postcode) {
      this.value.geojsonFeature = this.freeInputFeature
    }
  }

  async onSearchAdressChangeImpl (searchAdress: string): Promise<void> {
    try {
      if (searchAdress && searchAdress.length > 0) {
        const response = await axios.get('https://api-adresse.data.gouv.fr/search', {
          params: {
            q: searchAdress,
            type: 'housenumber',
            limit: 10
          }
        })
        this.foundAdresses = response.data.features
      } else {
        this.foundAdresses = []
      }
    } finally {
      this.loadingAdresses = false
    }
  }

  changeAdress (): void {
    this.geojsonFeatureStored = this.value.geojsonFeature
    this.value.geojsonFeature = null
  }

  cancelChangeAdress (): void {
    this.value.geojsonFeature = this.geojsonFeatureStored
    this.geojsonFeatureStored = null
  }
}
