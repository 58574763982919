















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { StoreDTO } from '@/api/dto'

@Component
export default class StoreLabel extends Vue {
  @Prop({ type: Object, required: true })
  value: StoreDTO
}
