import vuexStore from '../state/store'
import { BookingDTO, BookingStatus, StoreDTO } from '@/api/dto'
import { KeycloakTokenParsed } from 'keycloak-js'

export enum StorePermission {
  read = 'read',
  write = 'write',
  delete = 'delete',
  manageClosures = 'manageClosures'
}

export enum BookingPermission {
  read = 'read',
  readNotes = 'readNotes',
  write = 'write',
  writeNotes = 'writeNotes',
  writeFromDate = 'writeFromDate',
  writeToDate = 'writeToDate',
  writeUser = 'writeUser',
  confirm = 'confirm',
  cancel = 'cancel',
  delete = 'delete'
}

export default class AccessUtils {
  isAdmin (): boolean {
    const tokenParsed: KeycloakTokenParsed | undefined = vuexStore.state.auth.tokenParsed
    if (!tokenParsed) {
      return false
    }
    return tokenParsed.realm_access.roles.indexOf('planireza_admin') > -1
  }

  isAuthorized (): boolean {
    const tokenParsed: KeycloakTokenParsed | undefined = vuexStore.state.auth.tokenParsed
    if (!tokenParsed) {
      return false
    }
    return tokenParsed.realm_access.roles.indexOf('planireza_user') > -1
  }

  hasBookingPermission (store: StoreDTO, booking: BookingDTO, permission: BookingPermission): boolean {
    if (store.disabled && !this.isAdmin()) {
      return false
    }

    if (permission === BookingPermission.read) {
      if (this.isAdmin()) {
        return true
      }

      return this.isBookingOwner(booking) || this.isStoreAdmin(store)
    }

    if (permission === BookingPermission.readNotes) {
      if (this.isAdmin()) {
        return true
      }

      return false
    }

    if (permission === BookingPermission.writeToDate ||
      permission === BookingPermission.writeFromDate ||
      permission === BookingPermission.write ||
      permission === BookingPermission.delete) {
      if (this.isAdmin()) {
        return true
      }

      if (booking.status === BookingStatus.CONFIRMED) {
        return false
      }

      return this.isBookingOwner(booking) || this.isStoreAdmin(store)
    }

    if (permission === BookingPermission.writeUser) {
      if (this.isAdmin()) {
        return true
      }

      return false
    }

    if (permission === BookingPermission.writeNotes) {
      if (this.isAdmin()) {
        return true
      }

      return false
    }

    if (permission === BookingPermission.confirm) {
      if (!booking.status || booking.status === BookingStatus.CONFIRMED) {
        return false
      }

      if (this.isAdmin()) {
        return true
      }

      return this.isBookingOwner(booking) || this.isStoreAdmin(store)
    }

    if (permission === BookingPermission.cancel) {
      if (booking.status !== BookingStatus.CONFIRMED) {
        return false
      }

      if (this.isAdmin()) {
        return true
      }

      return false
    }

    return false
  }

  hasStorePermission (store: StoreDTO, permission: StorePermission): boolean {
    if (this.isAdmin()) {
      return true
    }

    if (store.disabled) {
      return false
    }

    if (permission === StorePermission.read || permission === StorePermission.manageClosures) {
      return this.isStoreAdmin(store)
    }

    return false
  }

  private isBookingOwner (object: BookingDTO): boolean {
    if (!object.user) {
      return false
    }
    const userId = vuexStore.getters['auth/userId']
    if (!userId) {
      return false
    }

    return object.user.id === userId
  }

  private isStoreAdmin (store: StoreDTO): boolean {
    const userId = vuexStore.getters['auth/userId']
    if (!userId) {
      return false
    }

    for (const user of store.admins) {
      if (user.id === userId) {
        return true
      }
    }

    return false
  }
}
