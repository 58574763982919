































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreClosureDTO } from '@/api/dto'
import moment from 'moment'
import MenuDatePicker from '@/components/menu-date-picker/MenuDatePicker.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { MenuDatePicker }
})
export default class StoreClosure extends Vue {
  @Prop({ type: Object })
  value: StoreClosureDTO

  allowedFromDates (date: string): boolean {
    if (this.value.toDate) {
      const fromMoment = moment(date)
      const toMoment = moment(this.value.toDate)

      return toMoment.isSameOrAfter(fromMoment)
    }
    return true
  }

  allowedToDates (date: string): boolean {
    if (this.value.fromDate) {
      const fromMoment = moment(this.value.fromDate)
      const toMoment = moment(date)

      return toMoment.isSameOrAfter(fromMoment)
    }
    return true
  }
}
