import store from '@/state/store'
import { UserExcerpt } from '@/api/dto'

export default class UsersUtils {
  userLabel (user: UserExcerpt): string {
    let label = ''
    if (user) {
      if (user.lastName) {
        label += user.lastName
      }
      if (user.firstName) {
        if (label) {
          label += ' '
        }
        label += user.firstName
      }
    }

    if (user && !label) {
      label = '(user)'
    }

    if (this.isCurrentUser(user)) {
      label += ' (Moi)'
    }
    return label
  }

  userComparator (a: UserExcerpt, b: UserExcerpt): boolean {
    return a.id === b.id
  }

  isCurrentUser (user: UserExcerpt): boolean {
    if (!user) {
      return false
    }
    const userId = store.getters['auth/userId']
    if (userId) {
      return userId === user.id
    }
    return false
  }
}
