

































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreReferenceDTO, StoreReferenceListExcerptDTO, StoreTemplateDTO } from '@/api/dto'
import { Prop, Watch } from 'vue-property-decorator'
import client from '@/api/client'
import StoreReferences from './StoreReferences.vue'
import StoreReferenceList from './StoreReferenceList.vue'
import { MenuEntry } from '@/state/menu'
import vuexStore from '@/state/store'
import { namespace } from 'vuex-class'

const Loading = namespace('loading')
const Menu = namespace('menu')

@Component({
  components: { StoreReferences, StoreReferenceList }
})
export default class StoreTemplateDetails extends Vue {
  @Prop({ type: Number })
  storeId: number

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  edit = false

  @Loading.Getter
  loading: boolean

  store: StoreTemplateDTO = null
  storeReferences: StoreReferenceDTO[] = []
  storeReferenceLists: StoreReferenceListExcerptDTO[] = []
  selectedReferenceList: StoreReferenceListExcerptDTO = null

  async created (): Promise<void> {
    vuexStore.commit('loading/startLoading', StoreTemplateDetails.name)

    try {
      const response = await Promise.all([
        client.get(`store-templates/${this.$route.params.id}`),
        client.get(`store-templates/${this.$route.params.id}/references?withComputedQuantity=true`),
        client.get(`store-templates/${this.$route.params.id}/possible-parent-store-reference-lists`),
        client.get(`store-templates/${this.$route.params.id}/store-reference-list/parent`)
      ])

      const store: StoreTemplateDTO = response[0].data
      const storeReferences: StoreReferenceDTO[] = response[1].data
      const storeReferenceLists: StoreReferenceListExcerptDTO[] = response[2].data
      const selectedReferenceList: StoreReferenceListExcerptDTO = response[3].data

      this.store = store
      this.storeReferences = storeReferences
      this.storeReferenceLists = storeReferenceLists
      this.selectedReferenceList = selectedReferenceList

      this.setMenuEntries([{
        label: 'Changer de magasin',
        icon: 'mdi-package-variant',
        action: () => this.$router.push({ name: 'Stores' })
      }])
    } catch (err) {
      Vue.config.errorHandler(err, this, null)
      throw err
    } finally {
      vuexStore.commit('loading/stopLoading', StoreTemplateDetails.name)
    }
  }

  async reloadReferences (): Promise<void> {
    const response = await client.get(`store-templates/${this.$route.params.id}/references?withComputedQuantity=true`)
    this.storeReferences = response.data
  }

  @Watch('selectedReferenceList')
  async onSelectedReferenceListChanged (): Promise<void> {
    await this.reloadReferences()
  }
}
