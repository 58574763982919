import { BookingDTO, BookingStatus } from '@/api/dto'
import moment from 'moment'

export default class BookingUtils {
  get statusList (): string[] {
    return Object.keys(BookingStatus)
  }

  statusLabel (status: BookingStatus): string {
    switch (status) {
      case BookingStatus.CONFIRMED:
        return 'Confirmée'
      case BookingStatus.WAITING:
        return 'En attente'
      default:
        return ''
    }
  }

  statusVariant (status: BookingStatus): string {
    switch (status) {
      case BookingStatus.CONFIRMED:
        return 'success'
      case BookingStatus.WAITING:
        return 'warning'
      default:
        return ''
    }
  }

  dateLabel (date: string, format = 'LL'): string {
    return moment(date, 'YYYY-MM-DD').format(format)
  }

  dateTimeLabel (dateTime: string, format = 'LL'): string {
    return moment(dateTime).format(format)
  }

  dateRangeLabel (booking: BookingDTO, format = 'LL'): string {
    const dates: string[] = []

    if (booking.fromDate) {
      dates.push(this.dateLabel(booking.fromDate, format))
    }

    if (booking.toDate) {
      dates.push(this.dateLabel(booking.toDate, format))
    }

    return dates.join(' - ')
  }
}
