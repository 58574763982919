
































import Vue from 'vue'
import Component from 'vue-class-component'

import ReferenceChild from './ReferenceChild.vue'
import { Prop } from 'vue-property-decorator'
import { ChildReferenceDTO, ReferenceDTO } from '@/api/dto'

@Component({
  components: { ReferenceChild }
})
export default class ReferenceChildren extends Vue {
  @Prop({ type: Array, required: true })
  value: ChildReferenceDTO[]

  @Prop({ type: Object, required: true })
  reference: ReferenceDTO

  @Prop({ type: Array })
  references: ReferenceDTO[]

  @Prop({ type: Boolean, default: false })
  edit: boolean

  addReference (): void {
    const childReference = new ChildReferenceDTO()
    this.value.push(childReference)
  }

  deleteChildReference (childReference: ChildReferenceDTO): void {
    const indexOf = this.value.indexOf(childReference)
    if (indexOf > -1) {
      Vue.delete(this.value, indexOf)
    }
  }
}
