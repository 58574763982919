




































import Vue from 'vue'
import Component from 'vue-class-component'
import { SnackbarEntry } from '@/state/snackbar'
import { Inject } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { RawLocation } from 'vue-router/types/router'
import * as auth from '@/services/auth'
import type { KeycloakTokenParsed } from 'keycloak-js'

const Auth = namespace('auth')
const Snackbar = namespace('snackbar')

@Component
export default class Login extends Vue {
  error: string = null

  @Inject()
  auth: typeof auth

  @Auth.Getter
  tokenParsed: KeycloakTokenParsed | null

  @Snackbar.Mutation
  setSnackbarEntry: (entry: SnackbarEntry) => void

  valid = false

  async mounted (): Promise<void> {
    let alreadyLoggedIn = true
    let authenticated = await this.auth.checkSso()

    if (!authenticated) {
      alreadyLoggedIn = false
      authenticated = await this.auth.login()
    }

    if (authenticated) {
      await this.$router.push(this.$router.currentRoute.query.redirect ? this.$router.currentRoute.query.redirect as RawLocation : { name: 'Bookings' })
      if (!alreadyLoggedIn) {
        this.setSnackbarEntry({ title: 'Connecté !', icon: 'mdi-checkbox-marked-circle', color: 'success' })
      }
    }
  }
}
