

























import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/types'
import { orderIdRegex } from '@/utils/rulesUtils'

@Component
export default class BookingConfirmDialog extends Vue {
  orderId = ''
  valid = false

  submit (): void {
    if ((this.$refs.form as VForm).validate()) {
      const orderId = this.orderId.match(orderIdRegex)[1]
      this.$emit('submit', { orderId })
    }
  }
}
