import { Module } from 'vuex'

import { RootState } from './store'

export interface SnackbarEntry {
  message?: string
  icon?: string
  title?: string
  color?: string
  multiLine?: boolean
  timeout?: number
  closable?: boolean
}

export interface SnackbarState {
  entry?: SnackbarEntry | null,
}

const snackbar = {
  namespaced: true,
  state: {
    entry: undefined
  },
  mutations: {
    setSnackbarEntry (state: SnackbarState, entry: SnackbarEntry | null) {
      if (entry && entry.closable === undefined) {
        entry.closable = true
      }
      state.entry = entry
    },
    clearSnackbar (state: SnackbarState) {
      state.entry = undefined
    }
  },
  getters: {
    currentSnackbarEntry (state: SnackbarState): SnackbarEntry | null {
      return state.entry
    }
  }
} as Module<SnackbarState, RootState>

export default snackbar
