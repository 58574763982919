



































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { BookingDTO, BookingStatus, ReferenceDTO, SearchQuery, StoreDTO, UserExcerpt } from '@/api/dto'
import client from '@/api/client'
import config from '@/config'
import MenuDatePicker from '@/components/menu-date-picker/MenuDatePicker.vue'
import moment from 'moment'
import { SnackbarEntry } from '@/state/snackbar'
import { MenuEntry } from '@/state/menu'
import { namespace } from 'vuex-class'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import { Inject } from 'vue-property-decorator'
import type * as authType from '@/services/auth'

const Auth = namespace('auth')
const Data = namespace('data')
const Menu = namespace('menu')
const Snackbar = namespace('snackbar')

@Component({
  components: { MenuDatePicker, ToolbarButtons }
})
export default class Search extends Vue {
  @Snackbar.Mutation
  setSnackbarEntry: (entry: SnackbarEntry) => void

  @Snackbar.Mutation
  clearSnackbar: () => void

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Data.Getter
  stores: StoreDTO[]

  @Data.Getter
  users: UserExcerpt[]

  @Auth.Getter
  token: string | null

  @Data.Getter
  references: ReferenceDTO[]

  @Inject()
  auth: typeof authType

  statuses: BookingStatus[] = [BookingStatus.WAITING, BookingStatus.CONFIRMED]

  progress = false

  search: SearchQuery = new SearchQuery()
  editSearch = true

  results: BookingDTO[] | null = null
  tableItems: {
    id: number;
    store: string
  }[] | null = null

  headersDef: { text: string, value: string }[] = [
    { text: 'Date', value: 'fromDate' },
    { text: 'Status', value: 'status' },
    { text: 'Magasin', value: 'store' },
    { text: 'Utilisateur', value: 'user' },
    { text: 'Client', value: 'client' }
  ]

  get headers (): { text: string, value: string }[] {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.headersDef.slice(0, 2)
    } else if (this.$vuetify.breakpoint.mdAndDown) {
      return this.headersDef.slice(0, 3)
    } else if (this.$vuetify.breakpoint.lgAndDown) {
      return this.headersDef.slice(0, 4)
    }
    return this.headersDef
  }

  created (): void {
    this.setMenuEntries([{
      label: 'Réserver',
      icon: 'mdi-calendar',
      action: () => this.$router.push({ name: 'Bookings' })
    }])
  }

  allowedFromDates (date: string): boolean {
    if (this.search.toDate) {
      const fromMoment = moment(date)
      const toMoment = moment(this.search.toDate)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  allowedToDates (date: string): boolean {
    if (this.search.fromDate) {
      const fromMoment = moment(this.search.fromDate)
      const toMoment = moment(date)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  allowedFromCreatedDates (date: string): boolean {
    if (this.search.toCreatedDate) {
      const fromMoment = moment(date)
      const toMoment = moment(this.search.toCreatedDate)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  allowedToCreatedDates (date: string): boolean {
    if (this.search.fromCreatedDate) {
      const fromMoment = moment(this.search.fromCreatedDate)
      const toMoment = moment(date)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  allowedFromConfirmationDates (date: string): boolean {
    if (this.search.toConfirmationDate) {
      const fromMoment = moment(date)
      const toMoment = moment(this.search.toConfirmationDate)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  allowedToConfirmationDates (date: string): boolean {
    if (this.search.fromConfirmationDate) {
      const fromMoment = moment(this.search.fromConfirmationDate)
      const toMoment = moment(date)

      return toMoment.isAfter(fromMoment)
    }
    return true
  }

  doReset (): void {
    this.search = new SearchQuery()
    this.results = null
    this.tableItems = null
  }

  doEditSearch (): void {
    this.editSearch = true
    this.results = null
    this.tableItems = null
  }

  async doSearch (): Promise<void> {
    this.clearSnackbar()

    this.progress = true

    try {
      const response = await client.post('search', this.search)

      const results = response.data

      if (results.length > 0) {
        this.results = results
        this.tableItems = this.results.map(r => {
          return {
            id: r.id,
            result: r,
            fromDate: moment(r.fromDate),
            toDate: r.toDate ? moment(r.toDate) : r.toDate,
            confirmationDate: r.confirmationDate ? moment(r.confirmationDate) : r.confirmationDate,
            store: r.store.label,
            user: this.$users.userLabel(r.user),
            userPhone: r.user.phone,
            orderId: r.orderId,
            client: r.client,
            clientPhone: r.clientPhone,
            references: r.references,
            status: r.status
          }
        })

        this.editSearch = false
      } else {
        this.editSearch = true

        this.setSnackbarEntry({
          title: 'Aucune réservation',
          message: 'Aucune réservation ne corresponds à cette recherche',
          icon: 'mdi-message-alert',
          color: 'info'
        })
      }
    } finally {
      this.progress = false
    }
  }

  async openBooking (booking: BookingDTO): Promise<void> {
    await this.$router.push({
      name: 'Booking',
      params: {
        storeId: booking.store.id.toString(),
        date: booking.fromDate,
        id: booking.id.toString()
      }
    })
  }

  async searchExport (): Promise<void> {
    await this.auth.updateToken()
    const query = encodeURIComponent(JSON.stringify(this.search))
    const url = `${config.api.baseURL}/search/export?query=${query}&access_token=${this.token}`
    window.location.href = url
  }
}
