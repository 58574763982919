













import Vue from 'vue'
import Component from 'vue-class-component'

import config from '@/config'

@Component
export default class Sso extends Vue {
  async mounted (): Promise<void> {
    window.location.href = config.sso.baseURL + window.location.search
  }
}
