import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import config from '@/config'

export const clientFactory: (config?: AxiosRequestConfig) => AxiosInstance = (config) => {
  return axios.create(config)
}
const client: AxiosInstance = clientFactory({
  ...config.api
})

export default client
export const rawClient = clientFactory({
  ...config.api
})
