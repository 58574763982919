
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { formatPhoneNumber, getPhoneNumberRegion } from '@/utils/phoneUtils'
import { Rule } from '@/utils/rulesUtils'

@Component
export default class PhoneNumberField extends Vue {
  @Prop({
    type: Boolean, default: false
  })
  required: boolean

  @Prop({
    type: String
  })
  value: string | null

  @Prop({
    type: String, default: 'Téléphone'
  })
  label: string | undefined | null

  @Prop({
    type: String
  })
  prependIcon: string | null

  inputValue: string | null = null

  @Watch('value', { immediate: true })
  onValueChange (value: string): void {
    this.inputValue = value
  }

  @Watch('inputValue')
  onInputValueChange (): void {
    this.$emit('input', this.inputValue)
  }

  get rules (): Rule<string>[] {
    const rules: Rule<string>[] = []
    if (this.required) {
      rules.push(this.$rules.required)
    }
    rules.push(this.$rules.phoneNumber)
    return rules
  }

  get regionCode (): string {
    return getPhoneNumberRegion(this.inputValue)
  }

  onBlur (): void {
    this.inputValue = formatPhoneNumber(this.inputValue)
  }
}
