import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/login/Login.vue'
import Main from '@/components/main/Main.vue'
import References from '@/components/references/References.vue'
import Bookings from '@/components/bookings/Bookings.vue'
import Stores from '@/components/stores/Stores.vue'
import StoreDetails from '@/components/stores/StoreDetails.vue'
import StoreTemplateDetails from '@/components/stores/StoreTemplateDetails.vue'
import StoreBookings from '@/components/bookings/StoreBookings.vue'
import Booking from '@/components/bookings/Booking.vue'
import Search from '@/components/search/Search.vue'
import Me from '@/components/me/Me.vue'
import store from '@/state/store'
import Users from '@/components/users/Users.vue'
import User from '@/components/users/User.vue'
import StoresClosures from '@/components/stores/StoresClosures.vue'
import StoreClosures from '@/components/stores/StoreClosures.vue'
import Sso from '@/components/login/Sso.vue'
import AccountDisabled from '@/components/account-disabled/AccountDisabled.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main,
      meta: { allowAnonymous: true },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login,
          meta: {
            allowAnonymous: true,
            layout: { fillHeight: true }
          }
        },
        {
          path: 'account-disabled',
          name: 'AccountDisabled',
          component: AccountDisabled,
          meta: {
            allowAnonymous: true,
            layout: { fillHeight: true }
          }
        },
        {
          path: 'references',
          name: 'References',
          component: References
        },
        {
          path: 'stores',
          name: 'Stores',
          component: Stores
        },
        {
          path: 'stores/:id',
          name: 'StoreDetails',
          component: StoreDetails
        },
        {
          path: 'stores/template/:id',
          name: 'StoreTemplateDetails',
          component: StoreTemplateDetails
        },
        {
          path: 'stores-closures',
          name: 'StoresClosures',
          component: StoresClosures
        },
        {
          path: 'stores-closures/:id',
          name: 'StoreClosures',
          component: StoreClosures,
          props: true
        },
        {
          path: 'bookings',
          alias: 'bookings/stores',
          name: 'Bookings',
          component: Bookings
        },
        {
          path: 'me',
          name: 'Me',
          component: Me
        },
        {
          path: 'search',
          name: 'Search',
          component: Search
        },
        {
          path: 'bookings/stores/:storeId/calendar/:date?',
          alias: 'bookings/stores/:storeId',
          name: 'StoreBookings',
          component: StoreBookings
        },
        {
          path: 'bookings/stores/:storeId/bookings/:date',
          name: 'AddBooking',
          component: Booking
        },
        {
          path: 'bookings/stores/:storeId/bookings/:date/:id',
          name: 'Booking',
          component: Booking
        },
        {
          path: 'users',
          name: 'Users',
          component: Users
        },
        {
          path: 'users/:id',
          name: 'User',
          component: User,
          props: true
        }
      ]
    },
    {
      path: '/sso',
      name: 'Sso',
      component: Sso,
      meta: { allowAnonymous: true }
    }
  ]
})

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.commit('menu/clear')
  }
})

router.beforeEach((to, from, next) => {
  if (store.state.auth.tokenParsed && to.name === 'Login') {
    next({ name: 'Main' })
  }
  if (to.matched.some(record => !record.meta.allowAnonymous) && !store.state.auth.tokenParsed) {
    next({
      name: 'Login',
      query: { redirect: (to.query && to.query.redirect ? to.query.redirect : to.path) }
    })
  } else {
    next()
  }
})

export default router
