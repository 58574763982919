import config from '@/config'
import Keycloak, { KeycloakInitOptions, KeycloakInstance } from 'keycloak-js'
import store from '@/state/store'

import client from '@/api/client'
import { AxiosError } from 'axios'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const keycloak: KeycloakInstance = new (Keycloak as any)(config.keycloak)

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

const token = urlParams.get('token') || localStorage.getItem('auth.token')
const refreshToken = urlParams.get('refreshToken') || localStorage.getItem('auth.refreshToken')

let shouldStoreToken = false
let shouldStoreRefreshToken = false

if (token) {
  shouldStoreToken = true
  localStorage.setItem('auth.token', token)
}

if (refreshToken) {
  shouldStoreRefreshToken = true
  localStorage.setItem('auth.refreshToken', refreshToken)
}

store.commit('init/setAuth', true)

keycloak.onAuthError = () => {
  store.commit('init/setAuth', false)
}

keycloak.onAuthLogout = () => {
  store.commit('init/setAuth', false)
  store.commit('auth/setToken', null)
  store.commit('auth/setTokenParsed', null)
  store.commit('auth/setProfile', keycloak.profile)
  store.commit('auth/setUserInfo', keycloak.userInfo)

  localStorage.removeItem('auth.token')
  localStorage.removeItem('auth.refreshToken')
}

keycloak.onAuthRefreshSuccess = () => {
  if (shouldStoreToken) {
    localStorage.setItem('auth.token', keycloak.token)
  }

  if (shouldStoreRefreshToken) {
    localStorage.setItem('auth.refreshToken', keycloak.refreshToken)
  }

  store.commit('auth/setToken', keycloak.token)
  store.commit('auth/setTokenParsed', keycloak.tokenParsed)
}

keycloak.onAuthSuccess = () => {
  store.commit('init/setAuth', true)
  store.commit('auth/setToken', keycloak.token)
  store.commit('auth/setTokenParsed', keycloak.tokenParsed)

  client.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {}
    }
    config.headers.authorization = 'Bearer ' + keycloak.token
    return config
  })

  client.interceptors.response.use((response) => response, async (error: AxiosError) => {
    const requestConfig = error.config
    const response = error.response

    if (requestConfig.headers && requestConfig.headers.authorization &&
      response && response.status === 401 && keycloak.isTokenExpired(5)) {
      const refreshed = await keycloak.updateToken(5)
      if (refreshed) {
        return client.request(requestConfig)
      }
    }

    throw error
  })

  return Promise.all([keycloak.loadUserProfile(), keycloak.loadUserInfo()]).then(() => {
    store.commit('auth/setProfile', keycloak.profile)
    store.commit('auth/setUserInfo', keycloak.userInfo)
  })
}

const ssoOptions: KeycloakInitOptions = {
  token,
  refreshToken,
  checkLoginIframe: token == null,
  useNonce: token == null
}

const keycloakLoginOptions: KeycloakInitOptions = {
  ...ssoOptions,
  onLoad: 'login-required'
}

const keycloakCheckSsoOptions: KeycloakInitOptions = {
  ...ssoOptions,
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
}

export const checkSso: () => Promise<boolean> = async () => {
  return keycloak.init(keycloakCheckSsoOptions)
}

export const login: () => Promise<boolean> = async () => {
  return keycloak.init(keycloakLoginOptions)
}

export const logout: () => Promise<void> = async () => {
  localStorage.removeItem('auth.token')
  localStorage.removeItem('auth.refreshToken')
  return keycloak.logout()
}

export const updateToken: () => Promise<boolean> = (minValidity = 30) => {
  return keycloak.updateToken(minValidity)
}
