import { Module } from 'vuex'

import { RootState } from './store'

export interface InitState {
  auth: boolean | null,
  api: boolean | null,
  errors: unknown[]
}

const init = {
  namespaced: true,
  state: {
    auth: null,
    api: null,
    errors: []
  },
  mutations: {
    setAuth (state: InitState, initialized: boolean) {
      state.auth = initialized
    },
    setApi (state: InitState, initialized: boolean) {
      state.api = initialized
    },
    addError (state: InitState, error: unknown) {
      state.errors.push(error)
    }
  },
  getters: {
    init (state: InitState): boolean {
      return state.auth === null || state.api === null
    },
    initOk (state: InitState): boolean {
      return state.auth === true && state.api === true
    },
    initFailed (state: InitState) {
      return state.auth === false || state.api === false
    },
    errors (state: InitState): unknown[] {
      return state.errors
    }
  }
} as Module<InitState, RootState>

export default init
