import { StoreDTO } from '@/api/dto'
import { Feature, Point } from 'geojson'

export default class GeoUtils {
  buildMapLink (feature?: Feature | null): string {
    if (feature && feature.geometry && (feature.geometry as Point).coordinates) {
      const coords = (feature.geometry as Point).coordinates
      return `https://www.google.com/maps/place/${coords[1]},${coords[0]}`
    }
    return null
  }

  cleanAddress (store?: StoreDTO | null): string | null {
    if (store && store.geojsonFeature && store.geojsonFeature.properties) {
      const properties = store.geojsonFeature.properties
      return `${properties.name}, ${properties.postcode} ${properties.city}`
    }
    return null
  }

  cleanCountryCode (store?: StoreDTO | null): string | null {
    if (store && store.geojsonFeature && store.geojsonFeature.properties) {
      const properties = store.geojsonFeature.properties
      return properties.country
    }
    return null
  }

  cleanCity (store?: StoreDTO | null): string | null {
    if (store && store.geojsonFeature && store.geojsonFeature.properties) {
      const properties = store.geojsonFeature.properties
      return `${properties.city}`
    }
    return null
  }

  cleanPostcode (store?: StoreDTO | null): string | null {
    if (store && store.geojsonFeature && store.geojsonFeature.properties) {
      const properties = store.geojsonFeature.properties
      return `${properties.postcode}`
    }
    return null
  }
}
