





























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { BookingDTO } from '@/api/dto'
import client from '@/api/client'
import moment from 'moment'
import { MenuEntry } from '@/state/menu'
import { namespace } from 'vuex-class'
import ToolbarButtons from '@/components/toolbar-buttons/ToolbarButtons.vue'
import type * as authType from '@/services/auth'

import config from '@/config'
import { Inject } from 'vue-property-decorator'

const Auth = namespace('auth')
const Menu = namespace('menu')

@Component({
  components: { ToolbarButtons }
})
export default class Me extends Vue {
  @Auth.Getter
  userId: string

  @Auth.Getter
  token: string | null

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Inject()
  auth: typeof authType

  results: BookingDTO[] | null = null
  tableItems: {
    id: number;
    store: string
  }[] | null = null

  headersDef: { text: string, value: string }[] = [
    { text: 'Date', value: 'fromDate' },
    { text: 'Status', value: 'status' },
    { text: 'Magasin', value: 'store' },
    { text: 'Utilisateur', value: 'user' },
    { text: 'Client', value: 'client' }
  ]

  get headers (): { text: string, value: string }[] {
    if (this.$vuetify.breakpoint.xs) {
      return this.headersDef.slice(0, 2)
    } else if (this.$vuetify.breakpoint.smAndDown) {
      return this.headersDef.slice(0, 3)
    } else if (this.$vuetify.breakpoint.mdAndDown) {
      return this.headersDef.slice(0, 4)
    }
    return this.headersDef
  }

  async created (): Promise<void> {
    this.setMenuEntries([{
      label: 'Réserver',
      icon: 'mdi-calendar',
      action: () => this.$router.push({ name: 'Bookings' })
    }])

    await this.doSearch()
  }

  async doSearch (): Promise<void> {
    const response = await client.post('search/me')

    const results = response.data
    this.results = results
    this.tableItems = this.results.map(r => {
      return {
        id: r.id,
        result: r,
        fromDate: moment(r.fromDate),
        toDate: r.toDate ? moment(r.toDate) : r.toDate,
        confirmationDate: r.confirmationDate ? moment(r.confirmationDate) : r.confirmationDate,
        store: r.store.label,
        user: this.$users.userLabel(r.user),
        userPhone: r.user.phone,
        orderId: r.orderId,
        client: r.client,
        clientPhone: r.clientPhone,
        references: r.references,
        status: r.status
      }
    })
  }

  async openBooking (booking: BookingDTO): Promise<void> {
    await this.$router.push({
      name: 'Booking',
      params: {
        storeId: booking.store.id.toString(),
        date: booking.fromDate,
        id: booking.id.toString()
      }
    })
  }

  async searchExport (): Promise<void> {
    await this.auth.updateToken()
    const url = `${config.api.baseURL}/search/me/export?access_token=${this.token}`
    window.location.href = url
  }
}
