
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ConnectedAs extends Vue {
  @Prop({ type: String, required: true })
  username: string
}
