
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { Rule } from '@/utils/rulesUtils'
import { VMenu } from '@/types'

@Component
export default class MenuDatePicker extends Vue {
  @Prop({
    type: Object,
    default: () => {
      // Empty object
    }
  })
  textFieldOptions: Record<string, unknown>

  @Prop({
    type: Object,
    default: () => {
      // Empty object
    }
  })
  datePickerOptions: Record<string, unknown>

  @Prop({ type: Boolean, default: false })
  toolbar: boolean

  @Prop()
  value: string

  @Prop()
  rules: Rule<unknown>[]

  @Prop()
  placeholder: string | null

  displayed = false

  date: string = null
  datePickerDate: string = null

  @Watch('value', { immediate: true })
  onValueChanged (newValue: string): void {
    this.date = newValue
  }

  @Watch('date')
  onSelectedDateChanged (newValue: string): void {
    this.$emit('input', newValue)
  }

  @Watch('datePickerDate')
  onPickerDateChanged (newValue: string): void {
    if (!this.toolbar) {
      (this.$refs.menu as VMenu).save(newValue)
    }
  }

  onTextFieldInput (newValue: string): void {
    if (!newValue) {
      this.date = null
    }
  }

  get formattedDate (): string {
    if (this.date) {
      return moment(this.date).format('LL')
    } else {
      return null
    }
  }
}
