




































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { ChildReferenceDTO, ReferenceDTO } from '@/api/dto'

@Component
export default class ReferenceChild extends Vue {
  @Prop({ type: Object, required: true })
  value: ChildReferenceDTO

  @Prop({ type: Boolean, default: false })
  edit: boolean

  @Prop({ type: Object, required: true })
  reference: ReferenceDTO

  @Prop({ type: Array })
  references: ReferenceDTO[]

  selectedReference: ReferenceDTO = null

  get otherReferences (): ReferenceDTO[] {
    const otherReferences = this.references.slice(0)

    const indexOf = otherReferences.indexOf(this.reference)
    if (indexOf > -1) {
      otherReferences.splice(indexOf, 1)
    }

    // TODO: Remove already selected references in the pack

    return otherReferences
  }

  @Watch('value.reference', { immediate: true })
  onReferenceChanged (newValue: ReferenceDTO): void {
    this.selectedReference = newValue
  }

  @Watch('selectedReference')
  onSelectedReferenceChanged (newValue: ReferenceDTO): void {
    this.value.reference = newValue
  }
}
