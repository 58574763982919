





















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ResizeSensor from 'css-element-queries/src/ResizeSensor'
import { BookingDTO, CalendarSlotDetailDTO, ReferenceQuantityDTO, StoreDTO } from '@/api/dto'
import client from '@/api/client'
import moment from 'moment'
import SlotBookings from './SlotBookings.vue'
import { MenuEntry } from '@/state/menu'
import { Inject, Watch } from 'vue-property-decorator'
import StoreAdminsList from '@/components/store-admins-list/StoreAdminsList.vue'

import vuexStore from '@/state/store'
import { RawLocation, Route } from 'vue-router/types/router'
import { namespace } from 'vuex-class'
import StoreLabel from '@/components/stores/StoreLabel.vue'
import CalendarService from '@/services/calendar'

interface LoadingStates {
  selectedDateCalendarSlotDetail: boolean,
  calendarSlotDetails: boolean
}

const Menu = namespace('menu')

@Component({
  components: { SlotBookings, StoreAdminsList, StoreLabel }
})
export default class StoreBookings extends Vue {
  store: StoreDTO = null
  availableReferences: ReferenceQuantityDTO[] = []
  calendarSlotDetails: CalendarSlotDetailDTO[] = []
  calendarSlotDetailByDate: { [date: string]: CalendarSlotDetailDTO; } = {}
  selectedDate: string = null
  selectedDateCalendarSlotDetail: CalendarSlotDetailDTO = null
  pickerDate: string = null
  pickerDateTimer: number
  fromMoment: moment.Moment = null
  toMoment: moment.Moment = null
  datePickerWidth = 0
  loading: LoadingStates = {
    selectedDateCalendarSlotDetail: false,
    calendarSlotDetails: false
  }

  resizeSensor?: ResizeSensor

  @Menu.Mutation
  setMenuEntries: (entries: MenuEntry[]) => void

  @Inject()
  calendar: CalendarService

  get cssVars (): Record<string, unknown> {
    return {
      '--datepicker-width': this.datePickerWidth + 'px'
    }
  }

  resizeSensorCallback (): void {
    const vDatePickerVue = this.$refs.calendar as Vue
    this.datePickerWidth = vDatePickerVue.$el.clientWidth
  }

  beforeDestroy (): void {
    if (this.resizeSensor) {
      this.resizeSensor.detach(this.resizeSensorCallback)
      this.resizeSensor = undefined
    }
  }

  @Watch('store', { immediate: true })
  onStoreChange (): void {
    this.$nextTick(() => {
      const vDatePickerVue = this.$refs.calendar as Vue
      if (vDatePickerVue) {
        this.resizeSensor = new ResizeSensor(vDatePickerVue.$el, this.resizeSensorCallback)
      } else {
        if (this.resizeSensor) {
          this.resizeSensor.detach(this.resizeSensorCallback)
          this.resizeSensor = undefined
        }
      }
    })
  }

  async created (): Promise<void> {
    this.setMenuEntries([{
      label: 'Changer de magasin',
      icon: 'mdi-package-variant',
      action: () => this.$router.push({ name: 'Bookings' })
    }])

    vuexStore.commit('loading/startLoading', StoreBookings.name)

    try {
      const response = await Promise.all([
        client.get(`stores/${this.$route.params.storeId}`),
        client.get(`stores/${this.$route.params.storeId}/availableReferences`)])

      const pickerMoment = moment(this.$route.params.date || moment(), 'YYYY-MM')
      const pickerDate = pickerMoment.format('YYYY-MM')
      const fromMoment = moment(pickerMoment).startOf('month')
      const toMoment = moment(fromMoment).add(1, 'month').add(-1, 'day')

      this.pickerDate = pickerDate
      this.fromMoment = fromMoment
      this.toMoment = toMoment

      const store: StoreDTO = response[0].data
      const availableReferences: ReferenceQuantityDTO[] = response[1].data

      this.store = store
      this.availableReferences = availableReferences

      await this.reloadCalendarSlotDetails()
    } catch (e) {
      Vue.config.errorHandler(e, null, null)
      throw e
    } finally {
      vuexStore.commit('loading/stopLoading', StoreBookings.name)
    }
  }

  beforeRouteEnter (to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: StoreBookings) => unknown) | void) => void): void {
    next(vm => {
      vm.updateFromRoute()
    })
  }

  beforeRouteUpdate (to: Route, from: Route,
    next: (to?: RawLocation | false | ((vm: StoreBookings) => unknown) | void) => void): void {
    this.updateFromRoute(to)
    next()
  }

  updateFromRoute (route?: Route): void {
    if (!route) {
      route = this.$route
    }

    if (route.params.date) {
      const selectedDateMoment = moment(route.params.date, 'YYYY-MM-DD')
      if (selectedDateMoment.isValid() && selectedDateMoment.format('YYYY-MM-DD') === route.params.date) {
        this.selectedDate = selectedDateMoment.format('YYYY-MM-DD')
        return
      }

      const pickerDateMoment = moment(route.params.date, 'YYYY-MM')
      if (pickerDateMoment.isValid() && pickerDateMoment.format('YYYY-MM') === route.params.date) {
        const pickerDate = pickerDateMoment.format('YYYY-MM')
        this.pickerDate = pickerDate

        if (this.pickerDateTimer) {
          clearTimeout(this.pickerDateTimer)
        }
        this.loading.calendarSlotDetails = true
        this.pickerDateTimer = window.setTimeout(() => {
          this.selectedDate = null
          this.fromMoment = moment(pickerDate, 'YYYY-MM').startOf('month')
          this.toMoment = moment(this.fromMoment).add(1, 'month')

          return this.reloadCalendarSlotDetails()
        }, 500)
        return
      }
    }

    this.selectedDate = null
  }

  async onSelectedDateChanged (date: string): Promise<void> {
    if (this.selectedDate && this.selectedDate === date) {
      date = date.substr(0, date.lastIndexOf('-'))
    }

    await this.$router.replace(Object.assign({}, this.$route, { params: Object.assign({}, this.$route.params, { date }) }))
  }

  @Watch('selectedDate')
  async onSelectedDateChange (selectedDate: string): Promise<void> {
    if (selectedDate) {
      const selectedDateCalendarSlotDetail: CalendarSlotDetailDTO = this.calendarSlotDetailByDate[selectedDate]
      if (selectedDateCalendarSlotDetail) {
        this.selectedDateCalendarSlotDetail = selectedDateCalendarSlotDetail
      } else {
        this.loading.selectedDateCalendarSlotDetail = true
        try {
          const response = await client.get(`stores/${this.$route.params.storeId}/calendarSlotDetails/${selectedDate}`)
          this.selectedDateCalendarSlotDetail = response.data
        } finally {
          this.loading.selectedDateCalendarSlotDetail = false
        }
      }
    } else {
      this.selectedDateCalendarSlotDetail = null
    }
  }

  async reloadCalendarSlotDetails (): Promise<void> {
    try {
      const ret = await this.calendar.calendarSlotDetails(this.availableReferences, this.$route.params.storeId, this.fromMoment, this.toMoment)

      this.calendarSlotDetails = ret.calendarSlotDetails
      this.calendarSlotDetailByDate = ret.calendarSlotDetailByDate

      if (!this.isAllowedDate(this.selectedDate)) {
        this.selectedDate = null
      }
    } finally {
      this.loading.calendarSlotDetails = false
    }
  }

  async setPickerDate (pickerDate: string): Promise<void> {
    await this.$router.replace(Object.assign({}, this.$route, { params: Object.assign({}, this.$route.params, { date: pickerDate }) }))
  }

  getEvent (date: string): boolean {
    return !!this.getEventColor(date)
  }

  getEventColor (date: string): 'error' | 'warning' | null {
    const calendarSlotDetail = this.calendarSlotDetailByDate[date]
    if (calendarSlotDetail) {
      if (calendarSlotDetail.availableReferences && calendarSlotDetail.availableReferences.length <= 0) {
        return 'error'
      }

      if (calendarSlotDetail.hasBookings || (calendarSlotDetail.bookings && calendarSlotDetail.bookings.length > 0)) {
        return 'warning'
      }
    }
    return null
  }

  isAllowedDate (date: string): boolean {
    const calendarSlotDetail = this.calendarSlotDetailByDate[date]
    return calendarSlotDetail && calendarSlotDetail.calendarSlot.fromDate === date
  }

  async book (slot: CalendarSlotDetailDTO): Promise<void> {
    await this.$router.push({
      name: 'AddBooking',
      params: {
        storeId: this.store.id.toString(),
        date: slot.calendarSlot.fromDate
      }
    })
  }

  async edit (slot: CalendarSlotDetailDTO, booking: BookingDTO): Promise<void> {
    await this.$router.push({
      name: 'Booking',
      params: {
        storeId: this.store.id.toString(),
        date: booking.fromDate,
        id: booking.id.toString()
      }
    })
  }

  async onSlotChanged (): Promise<void> {
    await this.reloadCalendarSlotDetails()
  }

  async reloadCalendarSlotDetail (slot: CalendarSlotDetailDTO): Promise<void> {
    const index = this.calendarSlotDetails.indexOf(slot)

    const response = await client.get(`stores/${this.store.id}/calendarSlotDetails/${slot.calendarSlot.fromDate}`)

    const calendarSlotDetail: CalendarSlotDetailDTO = response.data
    if (index >= 0) {
      this.calendarSlotDetails.splice(index, 1, calendarSlotDetail)
    }

    if (slot === this.selectedDateCalendarSlotDetail) {
      this.calendarSlotDetailByDate[this.selectedDateCalendarSlotDetail.calendarSlot.fromDate] = calendarSlotDetail
      await this.onSelectedDateChange(this.selectedDate)
    }
  }
}
