import { Module } from 'vuex'

import { RootState } from './store'

export interface LoadingState {
  entries: unknown[]
}

const loading = {
  namespaced: true,
  state: {
    entries: [],
    errors: []
  },
  mutations: {
    startLoading (state: LoadingState, entry: unknown) {
      state.entries.push(entry)
    },
    stopLoading (state: LoadingState, entry: unknown) {
      const indexOf = state.entries.indexOf(entry)
      if (indexOf >= -1) {
        state.entries.splice(indexOf, 1)
      }
    },
    stopLoadingAll (state: LoadingState) {
      state.entries.slice(0, state.entries.length)
    }
  },
  getters: {
    loading (state: LoadingState): boolean {
      return state.entries.length > 0
    }
  }
} as Module<LoadingState, RootState>

export default loading
