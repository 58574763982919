




































import Vue from 'vue'
import Component from 'vue-class-component'
import { StoreDTO } from '@/api/dto'
import StoreAdminsList from '@/components/store-admins-list/StoreAdminsList.vue'
import { StorePermission } from '@/utils/accessUtils'
import { namespace } from 'vuex-class'
import StoreLabel from '@/components/stores/StoreLabel.vue'

const Auth = namespace('auth')
const Data = namespace('data')
const Loading = namespace('loading')

@Component({
  components: { StoreAdminsList, StoreLabel }
})
export default class StoresClosures extends Vue {
  @Loading.Getter
  loading: boolean

  @Auth.Getter
  userId: string

  @Data.Getter
  stores: StoreDTO[]

  get userStores (): StoreDTO[] {
    return this.stores.filter(s => this.$access.hasStorePermission(s, StorePermission.manageClosures))
  }
}
